import {ArrowDropDown} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {Accordion, AccordionDetails, AccordionSummary, Button, Stack, Typography} from "@mui/material"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import {CheckboxInput, makeFormikCheckboxProps} from "components/CheckboxInput"
import FormSection from "components/FormSection"
import {useFormik} from "formik"
import React, {FC} from "react"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const validationSchema = yup.object().shape({
  drivingHistoryDisclosure: yup.boolean().isTrue("Required"),
  backgroundCheckDisclosure: yup.boolean().isTrue("Required")
})

const Trustee: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep} = props

  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const formik = useFormik({
    initialValues: {
      drivingHistoryDisclosure: application.drivingHistoryDisclosure,
      backgroundCheckDisclosure: application.backgroundCheckDisclosure,
      requestMVRBackgroundCopy: application.requestMVRBackgroundCopy
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      saveProgress({
        ...application,
        drivingHistoryDisclosure: values.drivingHistoryDisclosure,
        backgroundCheckDisclosure: values.backgroundCheckDisclosure,
        requestMVRBackgroundCopy: values.requestMVRBackgroundCopy
      })
      nextStep()
    }
  })

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <>
      <FormSection title="Agreements" disableTopPadding
      subtitle= {<>


    </>

      }
      >
        <Accordion sx={{
          backgroundColor: "#f5f5f5",
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          boxShadow: "none",
          color: "#000",
        }}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Application Agreements</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          To be Read and Signed by Applicant<br /><br />
    In the event of employment, I understand that false or misleading information given in my application or interview may result in discharge. I understand, also, that I am required to abide by all rules and regulations of the company.
    <br/><br />
    I hereby authorize E‐Style Transport, LLC and its designated agents and representatives to conduct a comprehensive review of my background as condition of my employment with E‐Style Transport, LLC. I understand that the scope of the background investigation may include, but not limited to the following areas: verification of social security number, credit reports, current and previous residences; employment history, education background, character references; drug testing, civil and criminal history records from any criminal justice agency in any or all federal, state, county jurisdictions; driving records, birth records, and any other public or private records.
    <br />
    <br />
    I further authorize any individual, company , firm, corporation, or public agency to divulge any and all information, verbal or written, pertaining to me, to E‐Style Transport, LLC, it’s leadership/management team, and/or its agents as necessary in evaluating my qualifications for employment. I further authorize the complete release of any records or data pertaining to me which the individual, company, firm, corporation, or public agency may have, to include information or data received from other sources. Including: verification of social security number, credit reports, current and previous residences; employment history, education background, character references; drug testing, civil and criminal history records from any criminal justice agency in any or all federal, state, county jurisdictions; driving records, birth records, and any other public or private records. E‐Style Transport, LLC and its designated agents and representatives shall maintain all information received from this authorization in a confidential manner in order to protect the applicant’s personal information.
    “I understand that information I provide regarding current and/or previous employers may be used, and those employer(s) will be contacted, for the purpose of investigating my safety performance history as required by 49 CFR 391.23(d) and (e). I understand that I have the right to: Review information provided by current/previous employers Have errors in the information corrected by previous employers and for those previous employers to resend the corrected information to the prospective employer; and Have a rebuttal statement attached to the alleged erroneous information, if the previous employer(s) and I cannot agree on the accuracy of the information.
    <br/>
    In the event of employment, I understand that false or misleading information given in my application or interview
may result in discharge. I understand, also, that I am required to abide by all rules and regulations of the company.
I hereby authorize E‐Style Transport, LLC and its designated agents and representatives to conduct a
comprehensive review of my background as condition of my employment with E‐Style Transport, LLC. I understand
that the scope of the background investigation may include, but not limited to the following areas: verification of
social security number, credit reports, current and previous residences; employment history, education
background, character references; drug testing, civil and criminal history records from any criminal justice agency
in any or all federal, state, county jurisdictions; driving records, birth records, and any other public or private
records.
<br/>
<br/>
I further authorize any individual, company , firm, corporation, or public agency to divulge any and all information,
verbal or written, pertaining to me, to E‐Style Transport, LLC, it’s leadership/management team, and/or its agents
as necessary in evaluating my qualifications for employment. I further authorize the complete release of any
records or data pertaining to me which the individual, company, firm, corporation, or public agency may have, to
include information or data received from other sources. Including: verification of social security number, credit
reports, current and previous residences; employment history, education background, character references; drug
testing, civil and criminal history records from any criminal justice agency in any or all federal, state, county
jurisdictions; driving records, birth records, and any other public or private records. E‐Style Transport, LLC and its
designated agents and representatives shall maintain all information received from this authorization in a
confidential manner in order to protect the applicant’s personal information.
<br/>
<br/>
“I understand that information I provide regarding current and/or previous employers may be used, and those
employer(s) will be contacted, for the purpose of investigating my safety performance history as required by 49
CFR 391.23(d) and (e). I understand that I have the right to:
<br/>
<ul>
  <li>Review information provided by current/previous employers</li>
  <li>Have errors in the information corrected by previous employers and for those previous employers to re‐
send the corrected information to the prospective employer; and</li>
<li>Have a rebuttal statement attached to the alleged erroneous information, if the previous employer(s) and
I cannot agree on the accuracy of the information.”</li>
</ul>
          </Typography>
        </AccordionDetails>
      </Accordion> 
        <Accordion sx={{
          backgroundColor: "#f5f5f5",
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          boxShadow: "none",
          color: "#000",
          mb:6
        }}
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
        >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>MVR Driver authorization and release</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          I understand and agree that: The information supplied on this release form is true and correct, to the best of my
knowledge. The company has my authorization to thoroughly investigate my background. I understand that the
background report may include, but is not limited to, the following areas: Motor Vehicle Records, FMCSA PSP Records,
DOT Drug Testing, DOT Pre-employment Verification and Identity Verification. I hereby authorize MVRCheck.com an
agent of the Company to make a thorough background investigation of all information given by me to the Company. I
release from liability all persons, companies, and corporations supplying that information. Furthermore, I release and
indemnify the Company and MVRCheck.com against any liability that might result from making such background
checks. Upon Request, Employer or MVRCheck.com will supply a copy of the completed background report along with
a copy of an individual’s rights under the Fair Credit Reporting Act. A copy of this form is as valid as the original.
<br/>
<br/>
DISCLAIMER:
<br/>
The services will be provided by MVRCheck.com., hereafter “MVCHK”. The Client agrees that it shall be responsible for any and all actions that it
takes based on any reports MVCHK provides and further agrees that it has a permissible purpose under FCRA and DPPA to request the
information. The Client shall defend, indemnify, and hold MVCHK and/or its subcontractors harmless from any and all losses, claims, demands,
liability, causes of action, judgments, costs, and attorney fees ariving out of this Agreement, except to the extent that MVCHK is negligent. The
Client hereby agrees to hold MVCHK and its officers, agents, employees, subcontractors, and independent subcontractors harmless on account of
any expense, cost, or damage resulting from the publishing by the Client, or the employees, agents, or independent subcontractors of the Client, of
any report information provided by MVCHK contrary to the terms of this Agreement or applicable laws, rules, or regulations. The Client certifies that
the information in the reports from MVCHK will not be given to or resold to any other person or user and that the requests for information will not be
made except within compliance with the FCRA and DPAA .
<br/>
<br/>
THE BELOW DISCLOSURE AND AUTHORIZATION LANGUAGE IS FOR MANDATORY USE BY
ALL ACCOUNT HOLDERS
<br/>
<br/>
IMPORTANT DISCLOSURE
REGARDING BACKGROUND REPORTS FROM THE PSP Online Service
<br/>
<br/>
In connection with your application for employment with E-Style Transport, LLC (&quot;Prospective Employer&quot;), Prospective
Employer, its employees, agents or contractors may obtain one or more reports regarding your driving, and safety inspection history
from the Federal Motor Carrier Safety Administration (FMCSA).
<br/>
<br/>
When the application for employment is submitted in person, if the Prospective Employer uses any information it obtains from
FMCSA in a decision to not hire you or to make any other adverse employment decision regarding you, the Prospective Employer
will provide you with a copy of the report upon which its decision was based and a written summary of your rights under the Fair
Credit Reporting Act before taking any final adverse action. If any final adverse action is taken against you based upon your driving
history or safety report, the Prospective Employer will notify you that the action has been taken and that the action was based in part
or in whole on this report.
<br/>
<br/>
When the application for employment is submitted by mail, telephone, computer, or other similar means, if the Prospective
Employer uses any information it obtains from FMCSA in a decision to not hire you or to make any other adverse employment
decision regarding you, the Prospective Employer must provide you within three business days of taking adverse action oral, written
or electronic notification: that adverse action has been taken based in whole or in part on information obtained from FMCSA; the
name, address, and the toll free telephone number of FMCSA; that the FMCSA did not make the decision to take the adverse action
and is unable to provide you the specific reasons why the adverse action was taken; and that you may, upon providing proper
identification, request a free copy of the report and may dispute with the FMCSA the accuracy or completeness of any information
or report. If you request a copy of a driver record from the Prospective Employer who procured the report, then, within 3 business
days of receiving your request, together with proper identification, the Prospective Employer must send or provide to you a copy of
your report and a summary of your rights under the Fair Credit Reporting Act.
<br/>
<br/>
Neither the Prospective Employer nor the FMCSA contractor supplying the crash and safety information has the capability to correct
any safety data that appears to be incorrect. You may challenge the accuracy of the data by submitting a request to
https://dataqs.fmcsa.dot.gov. If you challenge crash or inspection information reported by a State, FMCSA cannot change or correct
this data. Your request will be forwarded by the DataQs system to the appropriate State for adjudication.
<br/>
<br/>
Any crash or inspection in which you were involved will display on your PSP report. Since the PSP report does not report, or assign,
or imply fault, it will include all Commercial Motor Vehicle (CMV) crashes where you were a driver or co-driver and where those
crashes were reported to FMCSA, regardless of fault. Similarly, all inspections, with or without violations, appear on the PSP report.
State citations associated with Federal Motor Carrier Safety Regulations (FMCSR) violations that have been adjudicated by a court
of law will also appear, and remain, on a PSP report.
<br/>
<br/>
The Prospective Employer cannot obtain background reports from FMCSA without your authorization.
<br/>
<br/>
AUTHORIZATION
<br/>
<br/>
I authorize E-Style Transport, LLC 
(“Prospective Employer”) to access the FMCSA Pre-Employment Screening Program (PSP)
system to seek information regarding my commercial driving safety record and information regarding my safety inspection history. I
understand that I am authorizing the release of safety performance information including crash data from the previous five (5) years
and inspection history from the previous three (3) years. I understand and acknowledge that this release of information may assist
the Prospective Employer to make a determination regarding my suitability as an employee.
<br/>
<br/>
I further understand that neither the Prospective Employer nor the FMCSA contractor supplying the crash and safety information has
the capability to correct any safety data that appears to be incorrect. I understand I may challenge the accuracy of the data by
submitting a request to https://dataqs.fmcsa.dot.gov. If I challenge crash or inspection information reported by a State, FMCSA
cannot change or correct this data. I understand my request will be forwarded by the DataQs system to the appropriate State for
adjudication.
<br/>
<br/>
I understand that any crash or inspection in which I was involved will display on my PSP report. Since the PSP report does not
report, or assign, or imply fault, I acknowledge it will include all CMV crashes where I was a driver or co-driver and where those
crashes were reported to FMCSA, regardless of fault. Similarly, I understand all inspections, with or without violations, will appear on my PSP report, and State citations associated with FMCSR violations that have been adjudicated by a court of law will also
appear, and remain, on my PSP report. I have read the above Disclosure Regarding Background Reports provided to me by
Prospective Employer and I understand that if I sign this Disclosure and Authorization, Prospective Employer may obtain a report of
my crash and inspection history. I hereby authorize Prospective Employer and its employees, authorized agents, and/or affiliates to
obtain the information authorized above.
          </Typography>
        </AccordionDetails>
      </Accordion> 
        <Stack  spacing={4}>
               <CheckboxInput
            label="I have read the the above agreement and understand that G-Style will run a complete background check, authorize G-Style to run a complete background check and the G-Style is a drug free environment and passing a drug and alcohol test and being subject to random drug testing is a condition of employment"
            {...makeFormikCheckboxProps(formik, "backgroundCheckDisclosure")}
          />
          <CheckboxInput
            label="I have read the above MVR agreement and authorize you to run a MVR/PSP driving history"
            {...makeFormikCheckboxProps(formik, "drivingHistoryDisclosure")}
          />
          <CheckboxInput
            label="I request a copy of my MVR Background Report"
            {...makeFormikCheckboxProps(formik, "requestMVRBackgroundCopy")}
          />
        </Stack>
      </FormSection>

      <LoadingButton
        endIcon={<ArrowDropDown />}
        variant="contained"
        onClick={()=> {formik.submitForm()}}
        sx={{mt: 4}}
        loading={formik.isSubmitting}
        disabled={!formik.dirty}
      >
        Save and Continue
      </LoadingButton>
    </>
  )
}

export default Trustee
