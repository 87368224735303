import {faker} from "@faker-js/faker"
import {User} from "api/sdk"

export function generateUsers(total: number): User[] {
  return Array.from({length: total}, () => ({
    _id: faker.datatype.uuid(),
    email: faker.internet.email(),
    isSuperUser: true
  }))
}
