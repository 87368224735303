import {Clear, FileUpload, Image, InsertDriveFile} from "@mui/icons-material"
import {
  ButtonBase,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from "@mui/material"
import React, {FC, useRef} from "react"

export enum UploadType {
  Image = "Image",
  Document = "Document"
}

const acceptTypeMap: Record<UploadType, string> = {
  [UploadType.Image]: "image/*",
  [UploadType.Document]:
    "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
}

const acceptTypeDescriptionMap: Record<UploadType, string> = {
  [UploadType.Image]: "image files (.jpg, .png, .gif, .svg)",
  [UploadType.Document]:
    "document files (.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx)"
}

const iconMap: Record<UploadType, FC> = {
  [UploadType.Image]: Image,
  [UploadType.Document]: InsertDriveFile
}

export interface GStyleFileUploadprops {
  file: File | null
  setFile: (file: File | null) => void
  uploadType?: UploadType
  accept?: string
  acceptDescription?: string
  label?: string
  error?: boolean
  helperText?: string
}

export const GStyleFileUpload: FC<GStyleFileUploadprops> = (props) => {
  const {
    file,
    setFile,
    uploadType,
    label,
    error,
    helperText,
    accept,
    acceptDescription
  } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        setFile(file)
      }
      reader.readAsDataURL(file)
    }
  }

  const Icon = uploadType ? iconMap[uploadType] : InsertDriveFile

  return (
    <FormControl error={error}>
      {label && <FormLabel sx={{mb: 1}}>{label}</FormLabel>}
      {file ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <Tooltip title="Remove file">
            <IconButton
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.files = null
                  inputRef.current.value = ""
                }
                setFile(null)
              }}
              disabled={!file}
              aria-label="delete"
            >
              <Clear />
            </IconButton>
          </Tooltip>

          <Icon />

          <Tooltip title="Open file">
            <Typography
              color={error ? "error" : undefined}
              sx={{
                width: "350px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                cursor: "pointer"
              }}
              onClick={() => window.open(URL.createObjectURL(file))}
            >
              {file?.name}
            </Typography>
          </Tooltip>
          {uploadType === UploadType.Image && (
            <img
              style={{maxHeight: "50px", maxWidth: "50px"}}
              src={URL.createObjectURL(file)}
              alt="preview"
            />
          )}
        </Stack>
      ) : (
        <>
          <Stack direction="row">
            <ButtonBase onClick={() => inputRef.current?.click()}>
              <Stack
                direction="row"
                gap={2}
                alignItems="center"
                sx={{
                  border: "1px dashed",
                  borderColor: error ? "#F00" : "#AAA",
                  borderRadius: 1,
                  py: 1,
                  pl: 2,
                  pr: 3
                }}
              >
                <FileUpload />
                <Typography>Upload {uploadType}</Typography>
              </Stack>
            </ButtonBase>
          </Stack>
          <FormHelperText sx={{m: 0}}>
            {acceptDescription ??
              `Accepts ${
                uploadType
                  ? acceptTypeDescriptionMap[uploadType]
                  : "any file type"
              }`}
          </FormHelperText>
        </>
      )}

      <FormHelperText sx={{m: 0}}>{helperText}</FormHelperText>

      <input
        accept={(() => {
          if (accept) return accept
          if (uploadType) return acceptTypeMap[uploadType]
          return undefined
        })()}
        ref={inputRef}
        onChange={handleUploadChange}
        hidden
        multiple={false}
        type="file"
        id="hcp-upload"
      />
    </FormControl>
  )
}

export default GStyleFileUpload
