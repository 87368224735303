import {makeFormikTextFieldProps} from "@lightningkite/mui-lightning-components"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import {ArrowDropDown} from "@mui/icons-material"
import {Autocomplete, Box, Button, TextField} from "@mui/material"
import {Stack} from "@mui/system"
import {DatePicker} from "@mui/x-date-pickers"
import {ExperienceInfo, ExperienceItem} from "api/sdk"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import dayjs from "dayjs"
import {FieldArray, FormikProvider, useFormik} from "formik"
import React, {FC, useEffect} from "react"
import {handleArrayValidationErrors} from "utils/helpers/helpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"
import { LoadingButton } from "@mui/lab"

const validationSchema = yup.object().shape({
  totalCdlExperience: yup.number().required("Required"),
  experienceItems: yup.array().of(
    yup.object().shape({
      equipmentType: yup.string().required("Required"),
      startDate: yup.date().typeError("Please input date as MM/DD/YYYY").required("Required"),
      endDate: yup.date().typeError("Please input date as MM/DD/YYYY").required("Required"),
      approxMiles: yup.number().typeError("Please Enter a Number").required("Required")
    })
  )
})

type ExperienceItemErrorKey = keyof ExperienceItem | "selectedTypes"

const experienceInfoKeys: string[] = [
  "straightTruck",
  "tractorSemiTrailer",
  "tractorTwoTrailer",
  "other"
]

const experienceType = [
  {label: "Straight Truck", value: "straightTruck"},
  {label: "Tractor Semi Trailer", value: "tractorSemiTrailer"},
  {label: "Tractor Two Trailer", value: "tractorTwoTrailer"},
  {label: "Other", value: "other"}
]
const DrivingExperience: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep, requiresLicenseInfo} = props

  if (
    application.experienceInfo !== null &&
    application.experienceInfo !== undefined
  ) {
    Object.values(application.experienceInfo).map((item) => {
      if (typeof item === "string") {
        return item
      }
      return item
    })
  }

  const [arrayErrors, setArrayErrors] = React.useState<
    ReturnType<typeof handleArrayValidationErrors<ExperienceItemErrorKey>>
  >([])

  const [arrayExperienceItemsError, setArrayExperienceItemsError] = React.useState<String[]>(["","",""])

  const blankExperienceInfo: ExperienceItem = {
    equipmentType: "",
    startDate: "",
    endDate: "",
    approxMiles: null
  }

  const loadInitialExperienceInfo = () => {
    const initialExperienceArray = []
    const tempSelectedType = []
    for (const [key, value] of Object.entries(
      application.experienceInfo ?? {}
    )) {
      if (experienceInfoKeys.includes(key) && value !== null) {
        initialExperienceArray.push(value)
        tempSelectedType.push({
          label: experienceType.find((item) => item.value === key)?.label ?? "",
          value: key
        })
      }
    }
    return [initialExperienceArray, tempSelectedType]
  }

  const formik = useFormik({
    initialValues: {
      totalCdlExperience: application.experienceInfo?.totalCdlExperience ?? null,
      experienceItems:
        loadInitialExperienceInfo()[0].length > 0
          ? loadInitialExperienceInfo()[0]
          : [],
      selectedTypes:
        loadInitialExperienceInfo()[1].length > 0
          ? loadInitialExperienceInfo()[1]
          : ["","",""]
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      const tempExperienceInfo: ExperienceInfo = {
        totalCdlExperience: values.totalCdlExperience,
        straightTruck: null,
        tractorSemiTrailer: null,
        tractorTwoTrailer: null,
        other: null
      }
      setArrayExperienceItemsError(["","",""])
      formik.values.experienceItems.forEach((item, index) => {
        if (values.selectedTypes[index] ===undefined ||  values.selectedTypes[index].length===0){
          arrayExperienceItemsError[index] = "Please Add Vehicle Type"
          setArrayExperienceItemsError(arrayExperienceItemsError)
          throw new Error("it was udnefined detected")
        }
        if (values.selectedTypes[index].value === "straightTruck") {
          tempExperienceInfo.straightTruck = item
        }
        if (values.selectedTypes[index].value === "tractorSemiTrailer") {
          tempExperienceInfo.tractorSemiTrailer = item
        }
        if (values.selectedTypes[index].value === "tractorTwoTrailer") {
          tempExperienceInfo.tractorTwoTrailer = item
        }
        if (values.selectedTypes[index].value === "other") {
          tempExperienceInfo.other = item
        }
      })
      saveProgress({
        ...application,
        experienceInfo: tempExperienceInfo
      })
      nextStep()
    }
  })

  useEffect(() => {
    if (formik.submitCount === 0) return

    const errors = handleArrayValidationErrors<ExperienceItemErrorKey>(
      formik.errors.experienceItems
    )
    setArrayErrors(errors)
  }, [formik.errors, formik.submitCount])

  return (
    <>
      <FormikProvider value={formik}>
        <FormSection disableTopPadding title="Total CDL Experience">
          <TextField
            fullWidth
            label="Years of Experience"
            {...makeFormikTextFieldProps(formik, "totalCdlExperience")}
            helperText={"Enter a Number, If you have less than 1 year, enter an amount less than 1 such as if its 6 months, enter 0 if no experience"}
          />
        </FormSection>
        <FieldArray name="experienceItems">
          {({remove, push}) => (
            <>
              {formik.values.experienceItems.map((experience, index) => (
                <FormSection title={`Experience ${index + 1}`} key={index}
                formAction={
                  <Button
                    variant="contained"
                    sx={{alignSelf: "flex-start"}}
                    onClick={() => remove(index)}
                    startIcon={<DeleteIcon />}
                  >
                    Remove Experience
                  </Button>
                }
                >
                  <Autocomplete
                    disablePortal
                    fullWidth
                    options={experienceType}
                    value={formik.values.selectedTypes[index]}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        formik.values.selectedTypes[index] = newValue
                        formik.setFieldValue(
                          "selectedTypes",
                          formik.values.selectedTypes
                        )
                      }
                    }}
                    sx={{width: 300}}

                    renderInput={(params) => (
                      <TextField {...params} label="Vehicle Type"
                      error={
                        arrayExperienceItemsError[index].length > 0 
                      }
                      helperText={
                        arrayExperienceItemsError[index].length > 0 ? arrayExperienceItemsError[index] : ""
                      }
                      />
                    )}
                  />
                  <TextField
                    fullWidth
                    label="Equipment Type"
                    value={experience.equipmentType ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `experienceItems.${index}.equipmentType`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as ExperienceItem) !== undefined
                        ? (arrayErrors[index] as ExperienceItem).equipmentType
                        : ""
                    }
                    error={
                      (arrayErrors[index] as ExperienceItem) !== undefined
                        ? (arrayErrors[index] as ExperienceItem)
                            .equipmentType !== undefined
                        : false
                    }
                  />
                  <DatePicker
                    label="Start Date"
                    // {...makeFormikDateTimePickerProps(formik, "startDate")}
                    onChange={(e) => {
                      const date = dayjs(
                        e === null || e === undefined ? dayjs() : e
                      ).format("YYYY-MM-DD")
                      formik.setFieldValue(
                        `experienceItems.${index}.startDate`,
                        date
                      )
                    }}
                    value={formik.values.experienceItems[index].startDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          (arrayErrors[index] as ExperienceItem) !== undefined
                            ? (arrayErrors[index] as ExperienceItem).startDate
                            : ""
                        }
                        error={
                          (arrayErrors[index] as ExperienceItem) !== undefined
                            ? (arrayErrors[index] as ExperienceItem)
                                .startDate !== undefined
                            : false
                        }
                      />
                    )}
                    minDate={dayjs().subtract(120, "year")}
                    maxDate={dayjs()}
                  />
                  <DatePicker
                    label="End Date"
                    onChange={(e) => {
                      const date = dayjs(
                        e === null || e === undefined ? dayjs() : e
                      ).format("YYYY-MM-DD")
                      formik.setFieldValue(
                        `experienceItems.${index}.endDate`,
                        date
                      )
                    }}
                    value={formik.values.experienceItems[index].endDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          (arrayErrors[index] as ExperienceItem) !== undefined
                            ? (arrayErrors[index] as ExperienceItem).endDate
                            : ""
                        }
                        error={
                          (arrayErrors[index] as ExperienceItem) !== undefined
                            ? (arrayErrors[index] as ExperienceItem).endDate !==
                              undefined
                            : false
                        }
                      />
                    )}
                    minDate={dayjs().subtract(120, "year")}
                    maxDate={dayjs()}
                  />

                  <TextField
                    fullWidth
                    label="Approximate Miles"
                    value={experience.approxMiles ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `experienceItems.${index}.approxMiles`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as ExperienceItem) !== undefined
                        ? (arrayErrors[index] as ExperienceItem).approxMiles
                        : ""
                    }
                    error={
                      (arrayErrors[index] as ExperienceItem) !== undefined
                        ? (arrayErrors[index] as ExperienceItem).approxMiles !==
                          undefined
                        : false
                    }
                  />

                </FormSection>
              ))}
              {formik.values.experienceItems.length < 3 && (
                 <Box >
                <Button
                  variant="contained"
                  sx={{mt: 2}}
                  onClick={() => {
                    push(blankExperienceInfo)
                  }}
                  startIcon={<AddIcon />}
                >
                  Add Driving Experience
                </Button>
                </Box>
              )}
            </>
          )}
        </FieldArray>
          <LoadingButton
            endIcon={<ArrowDropDown />}
            variant="contained"
            onClick={()=>
              {
              formik.submitForm()
            }}
            sx={{mt: 4}}
            disabled={!formik.dirty}
          >
            Save and Continue
          </LoadingButton>
      </FormikProvider>
    </>
  )
}

export default DrivingExperience
