import {
  makeFormikDateTimePickerProps,
  makeFormikTextFieldProps
} from "@lightningkite/mui-lightning-components"
import {ArrowDropDown} from "@mui/icons-material"
import {Alert, AlertTitle, MenuItem, Stack, TextField} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers"
import {ApplicantInfo} from "api/sdk"
import {AuthContext} from "App"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import GStyleFileUpload, {UploadType} from "components/GStyleFileUpload"
import dayjs from "dayjs"
import {useFormik} from "formik"
import React, {FC, useContext, useEffect} from "react"
import {stateCodeOptions} from "utils/constants"
import {uploadBlobFile} from "utils/helpers/helpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"
import {LoadingButton} from "@mui/lab"

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  birthday: yup.date().typeError("Please input date as MM/DD/YYYY").required("Required"),
  ssn: yup.string().required("Required"),
  address1: yup.string().required("Required"),
  city: yup.string().required("Required"),
  state: yup.string().required("Required"),
  postalCode: yup.string().required("Required"),
  country: yup.string().required("Required"),
  phone: yup.string().required("Required"),
  email: yup.string().email().required("Required"),
  licenseNumber: yup.string().required("Required"),
  licensedStateIssued: yup.string().required("Required"),
})

const BusinessForm: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep} = props
  const {session} = useContext(AuthContext)
  const [licensePictureFile, setLicensePictureFile] =
    React.useState<File | null>(null)
  const [error, setError] = React.useState<string | null>(null)

  const uploadFile = async (file: File) => {
    const {uploadUrl, futureCallToken} = await session
      .uploadFileForRequest()
      .catch(() => {
        throw new Error("Error getting upload URL")
      })

    await uploadBlobFile(uploadUrl, file)
    return futureCallToken
  }

  const formik = useFormik({
    initialValues: {
      firstName: application.applicant?.firstName ?? "",
      lastName: application.applicant?.lastName ?? "",
      address1: application.applicant?.address?.line1 ?? "",
      address2: application.applicant?.address?.line2 ?? "",
      city: application.applicant?.address?.city ?? "",
      state: application.applicant?.address?.state ?? "",
      postalCode: application.applicant?.address?.postalCode ?? "",
      country: application.applicant?.address?.country ?? "",
      phone: application.applicant?.phone ?? "",
      email: application.applicant?.email ?? "",
      ssn: application.applicant?.socialSecurityNumber ?? "",
      birthday: application.applicant?.birthday ?? "",
      licenseNumber: application.applicant?.licenseNumber ?? "",
      licensePicture: application.applicant?.licensePicture ?? "",
      licensedStateIssued: application.applicant?.licensedStateIssued ?? "",
      tempLiscensePicture: null
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      const tempApplicant: ApplicantInfo = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        suffix: "",
        address: {
          line1: values.address1,
          line2: values.address2,
          city: values.city,
          state: values.state,
          postalCode: values.postalCode,
          country: values.country
        },
        phone: values.phone,
        socialSecurityNumber: values.ssn,
        birthday: dayjs(values.birthday).format("YYYY-MM-DD"),
        licenseNumber: values.licenseNumber,
        licensePicture: null,
        licensedStateIssued : values.licensedStateIssued
      }
      if (
        licensePictureFile === null &&
        application.applicant?.licensePicture === null
      ) {
        setError("Please upload a picture of your driver's license")
        throw new Error("Please upload a picture of your driver's license")
      }
      if (licensePictureFile !== null) {
        const futureCallToken = uploadFile(licensePictureFile)
        futureCallToken
          .then((token) => {
            tempApplicant.licensePicture = token
            saveProgress({
              ...application,
              applicant: tempApplicant
            })
            nextStep()
          })
          .catch((err) => {
            console.log(err)
            resetForm()
            setError("Error uploading file")
          })
      } else {
        saveProgress({
          ...application,
          applicant: tempApplicant
        })
        nextStep()
      }
    }
  })

  useEffect(() => {
    if (licensePictureFile !== null) {
      formik.setFieldValue("tempLiscensePicture", licensePictureFile.name)
    }
  }, [licensePictureFile])

  return (
    <>
      <FormSection disableTopPadding title="Personal Information" subtitle="">
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="First Name"
            {...makeFormikTextFieldProps(formik, "firstName")}
          />
          <TextField
            fullWidth
            label="Last Name"
            {...makeFormikTextFieldProps(formik, "lastName")}
          />
        </Stack>

        <DatePicker
          label="Birthday"
          {...makeFormikDateTimePickerProps(formik, "birthday")}
        />

        <TextField
          fullWidth
          label="Social Security Number"
          {...makeFormikTextFieldProps(formik, "ssn")}
        />
      </FormSection>

      <FormSection title="Contact information">
        <TextField
          label="Street Address"
          {...makeFormikTextFieldProps(formik, "address1")}
        />
        <TextField
          label="Address Line 2"
          {...makeFormikTextFieldProps(formik, "address2")}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="City"
            {...makeFormikTextFieldProps(formik, "city")}
          />
          <TextField
            fullWidth
            select
            label="State"
            {...makeFormikTextFieldProps(formik, "state")}
          >
            {stateCodeOptions.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            label="Zip"
            {...makeFormikTextFieldProps(formik, "postalCode")}
          />
        </Stack>
        <TextField
          label="Country"
          {...makeFormikTextFieldProps(formik, "country")}
        />

        <TextField
          fullWidth
          label="Email"
          {...makeFormikTextFieldProps(formik, "email")}
        />

        <TextField
          fullWidth
          label="Phone"
          {...makeFormikTextFieldProps(formik, "phone")}
        />
      </FormSection>

        <FormSection
          title="License Info"
          subtitle="Please upload a picture of your license and enter your license number"
        >
          <TextField
            fullWidth
            label="License Number"
            {...makeFormikTextFieldProps(formik, "licenseNumber")}
          />
                    <TextField
            fullWidth
            label="State License issued"
            {...makeFormikTextFieldProps(formik, "licensedStateIssued")}
          />

          {formik.values.licensePicture && (
            <Alert severity="info" sx={{mt: 2}}>
              <AlertTitle>License Picture already Uploaded</AlertTitle>
              <img src={formik.values.licensePicture} alt="License Picture" />
              <br></br>
              You can upload a new picture if you would like to change it
            </Alert>
          )}

          <GStyleFileUpload
            file={licensePictureFile}
            setFile={setLicensePictureFile}
            uploadType={UploadType.Image}
          ></GStyleFileUpload>
        </FormSection>

      <LoadingButton
        endIcon={<ArrowDropDown />}
        variant="contained"
        loading={formik.isSubmitting}
        onClick={()=>{
          formik.submitForm().catch((e) => {
            formik.setSubmitting(false)
          })
        }}
        disabled={!formik.dirty}
        sx={{mt: 4}}
      >
        Save and Continue
      </LoadingButton>

      {error && (
        <Alert severity="error" sx={{mt: 2}}>
          {error}
        </Alert>
      )}
    </>
  )
}

export default BusinessForm
