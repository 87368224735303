import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup
} from "@mui/material"
import {FormikValues, useFormik} from "formik"
import React, {ChangeEvent, FC} from "react"

export interface RadioInputProps {
  label?: string
  name: string
  options: Array<{value: string; label?: string}>
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void
  helperText?: string
  error?: boolean
}

export const RadioInput: FC<RadioInputProps> = (props) => {
  const {label, options, helperText, value, onChange, error, name} = props

  return (
    <FormControl error={error}>
      {label && <FormLabel>{label}</FormLabel>}
      <RadioGroup name={name} value={value} onChange={onChange}>
        {options.map(({value, label}) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio />}
            label={label ?? value}
          />
        ))}
      </RadioGroup>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export function makeFormikRadioInputProps<T extends FormikValues>(
  formik: ReturnType<typeof useFormik<T>>,
  field: keyof T
): Omit<RadioInputProps, "options"> {
  return {
    name: field.toString(),
    value: formik.values[field],
    onChange: formik.handleChange,
    error: formik.touched[field] && !!formik.errors[field],
    helperText: formik.touched[field] && (formik.errors[field] as string)
  }
}
