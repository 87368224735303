import UserJobPostings from "pages/JobPosting/UserJobPostings"
import {UserApplicationPage} from "pages/UserApplicationPage"
import React, {FC} from "react"
import {Navigate, Route, Routes} from "react-router-dom"

const UserRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/job-postings" element={<UserJobPostings />} />
      <Route
        path="/application/:applicationId/:requiresLicenseInfo"
        element={<UserApplicationPage />}
      />
      <Route path="*" element={<Navigate to="/job-postings" replace />} />
    </Routes>
  )
}

export default UserRoutes
