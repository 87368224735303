import {Button, Card, CardContent, Container} from "@mui/material"
import {User} from "api/sdk"
import {AuthContext} from "App"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import ErrorAlert from "components/ErrorAlert"
import Loading from "components/Loading"
import PageHeader from "components/PageHeader"
import React, {FC, useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {UserApplication} from "./UserApplications"
import {UserForm} from "./UserForm"

export const UserDetail: FC = () => {
  const {userId} = useParams()
  const {session, currentUser, refreshCurrentUser, logout} = useContext(AuthContext)
  const navigate = useNavigate()

  const [user, setUser] = useState<User | null>()

  if (userId === undefined) {
    throw new Error("User ID is undefined")
  }

  const refreshUser = async () => {
    // If the current user is editing their own profile, refresh the current user
    if (user?._id === currentUser._id) {
      refreshCurrentUser()
    }

    try {
      const newUser = await session.user.detail(userId)
      setUser(newUser)
    } catch {
      setUser(null)
    }
  }

  const deleteUser = async () => {
    const shouldDelete = confirm(
      "Are you sure you want to permanently delete this user?"
    )
    if (!shouldDelete) {
      return
    }
    await session.user
      .delete(userId)
      .then(() => navigate("/users"))
      .catch(() => alert("Error deleting user"))
  }

  useEffect(() => {
    refreshUser()
  }, [userId])

  if (user === undefined) {
    return <Loading />
  }

  if (user === null) {
    return <ErrorAlert>Error loading user
              <Button onClick={logout}>Log Out</Button>
    </ErrorAlert>
  }

  return (
    <Container maxWidth="md">
      <PageHeader
        title={user.email}
        breadcrumbs={[
          ["All Users", "/users"],
          [user.email, ""]
        ]}
      >
        <AutoLoadingButton onClick={deleteUser} color="error">
          Delete
        </AutoLoadingButton>
      </PageHeader>

      <Card>
        <CardContent sx={{mt: 2}}>
          <UserForm user={user} refreshUser={refreshUser} />
        </CardContent>
      </Card>

      <Card sx={{mt: 2}}>
        <CardContent>
          <UserApplication user={user} />
        </CardContent>
      </Card>
    </Container>
  )
}
