import { Query, MassModification, EntryChange, ListChange, Modification, Condition, GroupCountQuery, AggregateQuery, GroupAggregateQuery, Aggregate, apiCall, Path } from '@lightningkite/lightning-server-simplified'

export interface Address {
    line1: string | null | undefined
    line2: string | null | undefined
    city: string | null | undefined
    state: string | null | undefined
    country: string | null | undefined
    postalCode: string | null | undefined
}
export interface ApplicantInfo {
    email: string | null | undefined
    firstName: string | null | undefined
    lastName: string | null | undefined
    suffix: string | null | undefined
    address: Address | null | undefined
    phone: string | null | undefined
    socialSecurityNumber: string | null | undefined
    birthday: string | null | undefined
    licenseNumber: string | null | undefined
    licensedStateIssued: string | null | undefined
    licensePicture: string | null | undefined
}
export interface Application {
    _id: string
    owner: string
    jobPosting: string
    applicant: ApplicantInfo | null | undefined
    priorResidences: Array<PriorResidence>
    licenses: Array<LicenseInfo>
    experienceInfo: ExperienceInfo | null | undefined
    criminalInfo: CriminalInfo | null | undefined
    employmentHistory: Array<PastEmployerInfo>
    prevJob49CFRPart40: boolean | null | undefined
    drivingHistoryDisclosure: boolean
    backgroundCheckDisclosure: boolean
    requestMVRBackgroundCopy: boolean
    additionalDocuments: Array<string>
    signature: string | null | undefined
    pdfs: Array<string> | null | undefined
    readyForReview: boolean
    review: SignupFormReview | null | undefined
}

export interface ConvictionInfo {
    dateConvicted: string | null | undefined
    stateOfViolation: string | null | undefined
    natureOfViolation: string | null | undefined
    penaltyComments: string | null | undefined
}
export interface CriminalInfo {
    convictionHistory: Array<ConvictionInfo>
    beenDeniedExplain: string | null | undefined
    beenRevokedExplain: string | null | undefined
    failedDrug: boolean | null | undefined
    completedReturnToDuty: boolean | null | undefined
}
export interface DynamicSettings {
    _id: string
    pdfs: Array<string>
}
export interface EmailPinLogin {
    email: string
    pin: string
}
export interface ExperienceInfo {
    totalCdlExperience: number | null | undefined
    straightTruck: ExperienceItem | null | undefined
    tractorSemiTrailer: ExperienceItem | null | undefined
    tractorTwoTrailer: ExperienceItem | null | undefined
    other: ExperienceItem | null | undefined
}
export interface ExperienceItem {
    equipmentType: string | null | undefined
    startDate: string | null | undefined
    endDate: string | null | undefined
    approxMiles: number | null | undefined
}
export interface HealthStatus {
    level: Level
    checkedAt: string
    additionalMessage: string | null | undefined
}
export interface JobPosting {
    _id: string
    title: string
    positionsAvailable: number
    published: boolean
    description: string
    salaryRangeBegin: number
    salaryRangeEnd: number
    requiresLicenseInfo: boolean
}
export enum Level {
    OK = "OK",
    WARNING = "WARNING",
    URGENT = "URGENT",
    ERROR = "ERROR",
}
export interface LicenseInfo {
    state: string | null | undefined
    licenseNumber: string | null | undefined
    type: string | null | undefined
    expDate: string | null | undefined
}
export interface Memory {
    max: number
    total: number
    free: number
    systemAllocated: number
    usage: number
}
export interface PastEmployerInfo {
    name: string | null | undefined
    phone: string | null | undefined
    address: Address | null | undefined
    positionHeld: string | null | undefined
    startDate: string | null | undefined
    endDate: string | null | undefined
    salary: number | null | undefined
    subjectToFMCSR: string | null | undefined
    reasonForLeaving: string | null | undefined
    gapExplain: string | null | undefined
    designatedRepresentative: string | null | undefined
}
export interface PriorResidence {
    address: Address | null | undefined
    startYear: number | null | undefined
    endYear: number | null | undefined
}
export interface ServerHealth {
    serverId: string
    version: string
    memory: Memory
    features: Record<string, HealthStatus>
    loadAverageCpu: number
}
export interface SignatureInfo {
    _id: string
    application: Application
    timestamp: string
    emailAtSigning: string
    ipAddress: string
}
export interface SignupFormReview {
    status: SignupFormStatus
    at: string
    notes: string
}
export enum SignupFormStatus {
    ChangesRequested = "ChangesRequested",
    Rejected = "Rejected",
    Approved = "Approved",
}
export interface UploadInformation {
    uploadUrl: string
    futureCallToken: string
}
export interface User {
    _id: string
    email: string
    isSuperUser: boolean
}



export interface Api {
    readonly auth: {
        refreshToken(userToken: string): Promise<string>
        getSelf(userToken: string): Promise<User>
        anonymousToken(userToken?: string): Promise<string>
        emailLoginLink(input: string): Promise<void>
        emailPINLogin(input: EmailPinLogin): Promise<string>
    }
    readonly user: {
        default(userToken: string): Promise<User>
        query(input: Query<User>, userToken: string): Promise<Array<User>>
        detail(id: string, userToken: string): Promise<User>
        insertBulk(input: Array<User>, userToken: string): Promise<Array<User>>
        insert(input: User, userToken: string): Promise<User>
        upsert(id: string, input: User, userToken: string): Promise<User>
        bulkReplace(input: Array<User>, userToken: string): Promise<Array<User>>
        replace(id: string, input: User, userToken: string): Promise<User>
        bulkModify(input: MassModification<User>, userToken: string): Promise<number>
        modifyWithDiff(id: string, input: Modification<User>, userToken: string): Promise<EntryChange<User>>
        modify(id: string, input: Modification<User>, userToken: string): Promise<User>
        bulkDelete(input: Condition<User>, userToken: string): Promise<number>
        delete(id: string, userToken: string): Promise<void>
        count(input: Condition<User>, userToken: string): Promise<number>
        groupCount(input: GroupCountQuery<User>, userToken: string): Promise<Record<string, number>>
        aggregate(input: AggregateQuery<User>, userToken: string): Promise<number | null | undefined>
        groupAggregate(input: GroupAggregateQuery<User>, userToken: string): Promise<Record<string, number | null | undefined>>
    }
    readonly signatureInfo: {
        default(userToken: string): Promise<SignatureInfo>
        query(input: Query<SignatureInfo>, userToken: string): Promise<Array<SignatureInfo>>
        detail(id: string, userToken: string): Promise<SignatureInfo>
        insertBulk(input: Array<SignatureInfo>, userToken: string): Promise<Array<SignatureInfo>>
        insert(input: SignatureInfo, userToken: string): Promise<SignatureInfo>
        upsert(id: string, input: SignatureInfo, userToken: string): Promise<SignatureInfo>
        bulkReplace(input: Array<SignatureInfo>, userToken: string): Promise<Array<SignatureInfo>>
        replace(id: string, input: SignatureInfo, userToken: string): Promise<SignatureInfo>
        bulkModify(input: MassModification<SignatureInfo>, userToken: string): Promise<number>
        modifyWithDiff(id: string, input: Modification<SignatureInfo>, userToken: string): Promise<EntryChange<SignatureInfo>>
        modify(id: string, input: Modification<SignatureInfo>, userToken: string): Promise<SignatureInfo>
        bulkDelete(input: Condition<SignatureInfo>, userToken: string): Promise<number>
        delete(id: string, userToken: string): Promise<void>
        count(input: Condition<SignatureInfo>, userToken: string): Promise<number>
        groupCount(input: GroupCountQuery<SignatureInfo>, userToken: string): Promise<Record<string, number>>
        aggregate(input: AggregateQuery<SignatureInfo>, userToken: string): Promise<number | null | undefined>
        groupAggregate(input: GroupAggregateQuery<SignatureInfo>, userToken: string): Promise<Record<string, number | null | undefined>>
    }
    readonly application: {
        default(userToken: string): Promise<Application>
        query(input: Query<Application>, userToken: string): Promise<Array<Application>>
        detail(id: string, userToken: string): Promise<Application>
        insertBulk(input: Array<Application>, userToken: string): Promise<Array<Application>>
        insert(input: Application, userToken: string): Promise<Application>
        upsert(id: string, input: Application, userToken: string): Promise<Application>
        bulkReplace(input: Array<Application>, userToken: string): Promise<Array<Application>>
        replace(id: string, input: Application, userToken: string): Promise<Application>
        bulkModify(input: MassModification<Application>, userToken: string): Promise<number>
        modifyWithDiff(id: string, input: Modification<Application>, userToken: string): Promise<EntryChange<Application>>
        modify(id: string, input: Modification<Application>, userToken: string): Promise<Application>
        bulkDelete(input: Condition<Application>, userToken: string): Promise<number>
        delete(id: string, userToken: string): Promise<void>
        count(input: Condition<Application>, userToken: string): Promise<number>
        groupCount(input: GroupCountQuery<Application>, userToken: string): Promise<Record<string, number>>
        aggregate(input: AggregateQuery<Application>, userToken: string): Promise<number | null | undefined>
        groupAggregate(input: GroupAggregateQuery<Application>, userToken: string): Promise<Record<string, number | null | undefined>>
    }
    readonly jobPosting: {
        default(userToken: string): Promise<JobPosting>
        query(input: Query<JobPosting>, userToken: string): Promise<Array<JobPosting>>
        detail(id: string, userToken: string): Promise<JobPosting>
        insertBulk(input: Array<JobPosting>, userToken: string): Promise<Array<JobPosting>>
        insert(input: JobPosting, userToken: string): Promise<JobPosting>
        upsert(id: string, input: JobPosting, userToken: string): Promise<JobPosting>
        bulkReplace(input: Array<JobPosting>, userToken: string): Promise<Array<JobPosting>>
        replace(id: string, input: JobPosting, userToken: string): Promise<JobPosting>
        bulkModify(input: MassModification<JobPosting>, userToken: string): Promise<number>
        modifyWithDiff(id: string, input: Modification<JobPosting>, userToken: string): Promise<EntryChange<JobPosting>>
        modify(id: string, input: Modification<JobPosting>, userToken: string): Promise<JobPosting>
        bulkDelete(input: Condition<JobPosting>, userToken: string): Promise<number>
        delete(id: string, userToken: string): Promise<void>
        count(input: Condition<JobPosting>, userToken: string): Promise<number>
        groupCount(input: GroupCountQuery<JobPosting>, userToken: string): Promise<Record<string, number>>
        aggregate(input: AggregateQuery<JobPosting>, userToken: string): Promise<number | null | undefined>
        groupAggregate(input: GroupAggregateQuery<JobPosting>, userToken: string): Promise<Record<string, number | null | undefined>>
    }
    
    uploadFileForRequest(): Promise<UploadInformation>
    getServerHealth(userToken: string): Promise<ServerHealth>
}



export class UserSession {
    constructor(public api: Api, public userToken: string) {}
    getServerHealth(): Promise<ServerHealth> { return this.api.getServerHealth(this.userToken) } 
    uploadFileForRequest(): Promise<UploadInformation> { return this.api.uploadFileForRequest() } 
    readonly auth = {
        refreshToken: (): Promise<string> => { return this.api.auth.refreshToken(this.userToken) }, 
        getSelf: (): Promise<User> => { return this.api.auth.getSelf(this.userToken) }, 
        anonymousToken: (): Promise<string> => { return this.api.auth.anonymousToken(this.userToken) }, 
        emailLoginLink: (input: string): Promise<void> => { return this.api.auth.emailLoginLink(input) }, 
        emailPINLogin: (input: EmailPinLogin): Promise<string> => { return this.api.auth.emailPINLogin(input) }, 
    }

    readonly user = {
        default: (): Promise<User> => { return this.api.user.default(this.userToken) }, 
        query: (input: Query<User>): Promise<Array<User>> => { return this.api.user.query(input, this.userToken) }, 
        detail: (id: string): Promise<User> => { return this.api.user.detail(id, this.userToken) }, 
        insertBulk: (input: Array<User>): Promise<Array<User>> => { return this.api.user.insertBulk(input, this.userToken) }, 
        insert: (input: User): Promise<User> => { return this.api.user.insert(input, this.userToken) }, 
        upsert: (id: string, input: User): Promise<User> => { return this.api.user.upsert(id, input, this.userToken) }, 
        bulkReplace: (input: Array<User>): Promise<Array<User>> => { return this.api.user.bulkReplace(input, this.userToken) }, 
        replace: (id: string, input: User): Promise<User> => { return this.api.user.replace(id, input, this.userToken) }, 
        bulkModify: (input: MassModification<User>): Promise<number> => { return this.api.user.bulkModify(input, this.userToken) }, 
        modifyWithDiff: (id: string, input: Modification<User>): Promise<EntryChange<User>> => { return this.api.user.modifyWithDiff(id, input, this.userToken) }, 
        modify: (id: string, input: Modification<User>): Promise<User> => { return this.api.user.modify(id, input, this.userToken) }, 
        bulkDelete: (input: Condition<User>): Promise<number> => { return this.api.user.bulkDelete(input, this.userToken) }, 
        delete: (id: string): Promise<void> => { return this.api.user.delete(id, this.userToken) }, 
        count: (input: Condition<User>): Promise<number> => { return this.api.user.count(input, this.userToken) }, 
        groupCount: (input: GroupCountQuery<User>): Promise<Record<string, number>> => { return this.api.user.groupCount(input, this.userToken) }, 
        aggregate: (input: AggregateQuery<User>): Promise<number | null | undefined> => { return this.api.user.aggregate(input, this.userToken) }, 
        groupAggregate: (input: GroupAggregateQuery<User>): Promise<Record<string, number | null | undefined>> => { return this.api.user.groupAggregate(input, this.userToken) }, 
    }

    readonly signatureInfo = {
        default: (): Promise<SignatureInfo> => { return this.api.signatureInfo.default(this.userToken) }, 
        query: (input: Query<SignatureInfo>): Promise<Array<SignatureInfo>> => { return this.api.signatureInfo.query(input, this.userToken) }, 
        detail: (id: string): Promise<SignatureInfo> => { return this.api.signatureInfo.detail(id, this.userToken) }, 
        insertBulk: (input: Array<SignatureInfo>): Promise<Array<SignatureInfo>> => { return this.api.signatureInfo.insertBulk(input, this.userToken) }, 
        insert: (input: SignatureInfo): Promise<SignatureInfo> => { return this.api.signatureInfo.insert(input, this.userToken) }, 
        upsert: (id: string, input: SignatureInfo): Promise<SignatureInfo> => { return this.api.signatureInfo.upsert(id, input, this.userToken) }, 
        bulkReplace: (input: Array<SignatureInfo>): Promise<Array<SignatureInfo>> => { return this.api.signatureInfo.bulkReplace(input, this.userToken) }, 
        replace: (id: string, input: SignatureInfo): Promise<SignatureInfo> => { return this.api.signatureInfo.replace(id, input, this.userToken) }, 
        bulkModify: (input: MassModification<SignatureInfo>): Promise<number> => { return this.api.signatureInfo.bulkModify(input, this.userToken) }, 
        modifyWithDiff: (id: string, input: Modification<SignatureInfo>): Promise<EntryChange<SignatureInfo>> => { return this.api.signatureInfo.modifyWithDiff(id, input, this.userToken) }, 
        modify: (id: string, input: Modification<SignatureInfo>): Promise<SignatureInfo> => { return this.api.signatureInfo.modify(id, input, this.userToken) }, 
        bulkDelete: (input: Condition<SignatureInfo>): Promise<number> => { return this.api.signatureInfo.bulkDelete(input, this.userToken) }, 
        delete: (id: string): Promise<void> => { return this.api.signatureInfo.delete(id, this.userToken) }, 
        count: (input: Condition<SignatureInfo>): Promise<number> => { return this.api.signatureInfo.count(input, this.userToken) }, 
        groupCount: (input: GroupCountQuery<SignatureInfo>): Promise<Record<string, number>> => { return this.api.signatureInfo.groupCount(input, this.userToken) }, 
        aggregate: (input: AggregateQuery<SignatureInfo>): Promise<number | null | undefined> => { return this.api.signatureInfo.aggregate(input, this.userToken) }, 
        groupAggregate: (input: GroupAggregateQuery<SignatureInfo>): Promise<Record<string, number | null | undefined>> => { return this.api.signatureInfo.groupAggregate(input, this.userToken) }, 
    }

    readonly application = {
        default: (): Promise<Application> => { return this.api.application.default(this.userToken) }, 
        query: (input: Query<Application>): Promise<Array<Application>> => { return this.api.application.query(input, this.userToken) }, 
        detail: (id: string): Promise<Application> => { return this.api.application.detail(id, this.userToken) }, 
        insertBulk: (input: Array<Application>): Promise<Array<Application>> => { return this.api.application.insertBulk(input, this.userToken) }, 
        insert: (input: Application): Promise<Application> => { return this.api.application.insert(input, this.userToken) }, 
        upsert: (id: string, input: Application): Promise<Application> => { return this.api.application.upsert(id, input, this.userToken) }, 
        bulkReplace: (input: Array<Application>): Promise<Array<Application>> => { return this.api.application.bulkReplace(input, this.userToken) }, 
        replace: (id: string, input: Application): Promise<Application> => { return this.api.application.replace(id, input, this.userToken) }, 
        bulkModify: (input: MassModification<Application>): Promise<number> => { return this.api.application.bulkModify(input, this.userToken) }, 
        modifyWithDiff: (id: string, input: Modification<Application>): Promise<EntryChange<Application>> => { return this.api.application.modifyWithDiff(id, input, this.userToken) }, 
        modify: (id: string, input: Modification<Application>): Promise<Application> => { return this.api.application.modify(id, input, this.userToken) }, 
        bulkDelete: (input: Condition<Application>): Promise<number> => { return this.api.application.bulkDelete(input, this.userToken) }, 
        delete: (id: string): Promise<void> => { return this.api.application.delete(id, this.userToken) }, 
        count: (input: Condition<Application>): Promise<number> => { return this.api.application.count(input, this.userToken) }, 
        groupCount: (input: GroupCountQuery<Application>): Promise<Record<string, number>> => { return this.api.application.groupCount(input, this.userToken) }, 
        aggregate: (input: AggregateQuery<Application>): Promise<number | null | undefined> => { return this.api.application.aggregate(input, this.userToken) }, 
        groupAggregate: (input: GroupAggregateQuery<Application>): Promise<Record<string, number | null | undefined>> => { return this.api.application.groupAggregate(input, this.userToken) }, 
    }

    readonly jobPosting = {
        default: (): Promise<JobPosting> => { return this.api.jobPosting.default(this.userToken) }, 
        query: (input: Query<JobPosting>): Promise<Array<JobPosting>> => { return this.api.jobPosting.query(input, this.userToken) }, 
        detail: (id: string): Promise<JobPosting> => { return this.api.jobPosting.detail(id, this.userToken) }, 
        insertBulk: (input: Array<JobPosting>): Promise<Array<JobPosting>> => { return this.api.jobPosting.insertBulk(input, this.userToken) }, 
        insert: (input: JobPosting): Promise<JobPosting> => { return this.api.jobPosting.insert(input, this.userToken) }, 
        upsert: (id: string, input: JobPosting): Promise<JobPosting> => { return this.api.jobPosting.upsert(id, input, this.userToken) }, 
        bulkReplace: (input: Array<JobPosting>): Promise<Array<JobPosting>> => { return this.api.jobPosting.bulkReplace(input, this.userToken) }, 
        replace: (id: string, input: JobPosting): Promise<JobPosting> => { return this.api.jobPosting.replace(id, input, this.userToken) }, 
        bulkModify: (input: MassModification<JobPosting>): Promise<number> => { return this.api.jobPosting.bulkModify(input, this.userToken) }, 
        modifyWithDiff: (id: string, input: Modification<JobPosting>): Promise<EntryChange<JobPosting>> => { return this.api.jobPosting.modifyWithDiff(id, input, this.userToken) }, 
        modify: (id: string, input: Modification<JobPosting>): Promise<JobPosting> => { return this.api.jobPosting.modify(id, input, this.userToken) }, 
        bulkDelete: (input: Condition<JobPosting>): Promise<number> => { return this.api.jobPosting.bulkDelete(input, this.userToken) }, 
        delete: (id: string): Promise<void> => { return this.api.jobPosting.delete(id, this.userToken) }, 
        count: (input: Condition<JobPosting>): Promise<number> => { return this.api.jobPosting.count(input, this.userToken) }, 
        groupCount: (input: GroupCountQuery<JobPosting>): Promise<Record<string, number>> => { return this.api.jobPosting.groupCount(input, this.userToken) }, 
        aggregate: (input: AggregateQuery<JobPosting>): Promise<number | null | undefined> => { return this.api.jobPosting.aggregate(input, this.userToken) }, 
        groupAggregate: (input: GroupAggregateQuery<JobPosting>): Promise<Record<string, number | null | undefined>> => { return this.api.jobPosting.groupAggregate(input, this.userToken) }, 
    }


}




export class LiveApi implements Api {
    public constructor(public httpUrl: string, public socketUrl: string = httpUrl, public extraHeaders: Record<string, string> = {}) {}
    uploadFileForRequest(): Promise<UploadInformation> {
        return apiCall(`${this.httpUrl}/upload-early`, undefined, {
            method: "GET",
            }, 
        ).then(x => x.json())
    }

    getServerHealth(userToken: string): Promise<ServerHealth> {
        return apiCall(`${this.httpUrl}/meta/health`, undefined, {
            method: "GET",
            headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
        ).then(x => x.json())
    }

    readonly auth = {
        refreshToken: (userToken: string): Promise<string> => {
            return apiCall(`${this.httpUrl}/auth/refresh-token`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        getSelf: (userToken: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/auth/self`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        anonymousToken: (userToken?: string): Promise<string> => {
            return apiCall(`${this.httpUrl}/auth/anonymous`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        emailLoginLink: (input: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/auth/login-email`, input, {
                method: "POST",
            }, 
            ).then(x => undefined)
        },
        emailPINLogin: (input: EmailPinLogin): Promise<string> => {
            return apiCall(`${this.httpUrl}/auth/login-email-pin`, input, {
                method: "POST",
            }, 
            ).then(x => x.json())
        },
    }

    readonly user = {
        default: (userToken: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/user/rest/_default_`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        query: (input: Query<User>, userToken: string): Promise<Array<User>> => {
            return apiCall(`${this.httpUrl}/user/rest/query`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        detail: (id: string, userToken: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/user/rest/${id}`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insertBulk: (input: Array<User>, userToken: string): Promise<Array<User>> => {
            return apiCall(`${this.httpUrl}/user/rest/bulk`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insert: (input: User, userToken: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/user/rest`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        upsert: (id: string, input: User, userToken: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/user/rest/${id}`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkReplace: (input: Array<User>, userToken: string): Promise<Array<User>> => {
            return apiCall(`${this.httpUrl}/user/rest`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        replace: (id: string, input: User, userToken: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/user/rest/${id}`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkModify: (input: MassModification<User>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/user/rest/bulk`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modifyWithDiff: (id: string, input: Modification<User>, userToken: string): Promise<EntryChange<User>> => {
            return apiCall(`${this.httpUrl}/user/rest/${id}/delta`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modify: (id: string, input: Modification<User>, userToken: string): Promise<User> => {
            return apiCall(`${this.httpUrl}/user/rest/${id}`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkDelete: (input: Condition<User>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/user/rest/bulk-delete`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        delete: (id: string, userToken: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/user/rest/${id}`, undefined, {
                method: "DELETE",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => undefined)
        },
        count: (input: Condition<User>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/user/rest/count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupCount: (input: GroupCountQuery<User>, userToken: string): Promise<Record<string, number>> => {
            return apiCall(`${this.httpUrl}/user/rest/group-count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        aggregate: (input: AggregateQuery<User>, userToken: string): Promise<number | null | undefined> => {
            return apiCall(`${this.httpUrl}/user/rest/aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupAggregate: (input: GroupAggregateQuery<User>, userToken: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall(`${this.httpUrl}/user/rest/group-aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
    }

    readonly signatureInfo = {
        default: (userToken: string): Promise<SignatureInfo> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/_default_`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        query: (input: Query<SignatureInfo>, userToken: string): Promise<Array<SignatureInfo>> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/query`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        detail: (id: string, userToken: string): Promise<SignatureInfo> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/${id}`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insertBulk: (input: Array<SignatureInfo>, userToken: string): Promise<Array<SignatureInfo>> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/bulk`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insert: (input: SignatureInfo, userToken: string): Promise<SignatureInfo> => {
            return apiCall(`${this.httpUrl}/signature-info/rest`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        upsert: (id: string, input: SignatureInfo, userToken: string): Promise<SignatureInfo> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/${id}`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkReplace: (input: Array<SignatureInfo>, userToken: string): Promise<Array<SignatureInfo>> => {
            return apiCall(`${this.httpUrl}/signature-info/rest`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        replace: (id: string, input: SignatureInfo, userToken: string): Promise<SignatureInfo> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/${id}`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkModify: (input: MassModification<SignatureInfo>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/bulk`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modifyWithDiff: (id: string, input: Modification<SignatureInfo>, userToken: string): Promise<EntryChange<SignatureInfo>> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/${id}/delta`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modify: (id: string, input: Modification<SignatureInfo>, userToken: string): Promise<SignatureInfo> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/${id}`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkDelete: (input: Condition<SignatureInfo>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/bulk-delete`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        delete: (id: string, userToken: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/${id}`, undefined, {
                method: "DELETE",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => undefined)
        },
        count: (input: Condition<SignatureInfo>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupCount: (input: GroupCountQuery<SignatureInfo>, userToken: string): Promise<Record<string, number>> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/group-count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        aggregate: (input: AggregateQuery<SignatureInfo>, userToken: string): Promise<number | null | undefined> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupAggregate: (input: GroupAggregateQuery<SignatureInfo>, userToken: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall(`${this.httpUrl}/signature-info/rest/group-aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
    }

    readonly application = {
        default: (userToken: string): Promise<Application> => {
            return apiCall(`${this.httpUrl}/application/rest/_default_`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        query: (input: Query<Application>, userToken: string): Promise<Array<Application>> => {
            return apiCall(`${this.httpUrl}/application/rest/query`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        detail: (id: string, userToken: string): Promise<Application> => {
            return apiCall(`${this.httpUrl}/application/rest/${id}`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insertBulk: (input: Array<Application>, userToken: string): Promise<Array<Application>> => {
            return apiCall(`${this.httpUrl}/application/rest/bulk`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insert: (input: Application, userToken: string): Promise<Application> => {
            return apiCall(`${this.httpUrl}/application/rest`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        upsert: (id: string, input: Application, userToken: string): Promise<Application> => {
            return apiCall(`${this.httpUrl}/application/rest/${id}`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkReplace: (input: Array<Application>, userToken: string): Promise<Array<Application>> => {
            return apiCall(`${this.httpUrl}/application/rest`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        replace: (id: string, input: Application, userToken: string): Promise<Application> => {
            return apiCall(`${this.httpUrl}/application/rest/${id}`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkModify: (input: MassModification<Application>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/application/rest/bulk`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modifyWithDiff: (id: string, input: Modification<Application>, userToken: string): Promise<EntryChange<Application>> => {
            return apiCall(`${this.httpUrl}/application/rest/${id}/delta`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modify: (id: string, input: Modification<Application>, userToken: string): Promise<Application> => {
            return apiCall(`${this.httpUrl}/application/rest/${id}`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkDelete: (input: Condition<Application>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/application/rest/bulk-delete`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        delete: (id: string, userToken: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/application/rest/${id}`, undefined, {
                method: "DELETE",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => undefined)
        },
        count: (input: Condition<Application>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/application/rest/count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupCount: (input: GroupCountQuery<Application>, userToken: string): Promise<Record<string, number>> => {
            return apiCall(`${this.httpUrl}/application/rest/group-count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        aggregate: (input: AggregateQuery<Application>, userToken: string): Promise<number | null | undefined> => {
            return apiCall(`${this.httpUrl}/application/rest/aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupAggregate: (input: GroupAggregateQuery<Application>, userToken: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall(`${this.httpUrl}/application/rest/group-aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
    }

    readonly jobPosting = {
        default: (userToken: string): Promise<JobPosting> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/_default_`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        query: (input: Query<JobPosting>, userToken: string): Promise<Array<JobPosting>> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/query`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        detail: (id: string, userToken: string): Promise<JobPosting> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/${id}`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insertBulk: (input: Array<JobPosting>, userToken: string): Promise<Array<JobPosting>> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/bulk`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insert: (input: JobPosting, userToken: string): Promise<JobPosting> => {
            return apiCall(`${this.httpUrl}/job-posting/rest`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        upsert: (id: string, input: JobPosting, userToken: string): Promise<JobPosting> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/${id}`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkReplace: (input: Array<JobPosting>, userToken: string): Promise<Array<JobPosting>> => {
            return apiCall(`${this.httpUrl}/job-posting/rest`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        replace: (id: string, input: JobPosting, userToken: string): Promise<JobPosting> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/${id}`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkModify: (input: MassModification<JobPosting>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/bulk`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modifyWithDiff: (id: string, input: Modification<JobPosting>, userToken: string): Promise<EntryChange<JobPosting>> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/${id}/delta`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modify: (id: string, input: Modification<JobPosting>, userToken: string): Promise<JobPosting> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/${id}`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkDelete: (input: Condition<JobPosting>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/bulk-delete`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        delete: (id: string, userToken: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/${id}`, undefined, {
                method: "DELETE",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => undefined)
        },
        count: (input: Condition<JobPosting>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupCount: (input: GroupCountQuery<JobPosting>, userToken: string): Promise<Record<string, number>> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/group-count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        aggregate: (input: AggregateQuery<JobPosting>, userToken: string): Promise<number | null | undefined> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupAggregate: (input: GroupAggregateQuery<JobPosting>, userToken: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall(`${this.httpUrl}/job-posting/rest/group-aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
    }

    readonly dynamicSettings = {
        default: (userToken: string): Promise<DynamicSettings> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/_default_`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        query: (input: Query<DynamicSettings>, userToken: string): Promise<Array<DynamicSettings>> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/query`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        detail: (id: string, userToken: string): Promise<DynamicSettings> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/${id}`, undefined, {
                method: "GET",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insertBulk: (input: Array<DynamicSettings>, userToken: string): Promise<Array<DynamicSettings>> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/bulk`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        insert: (input: DynamicSettings, userToken: string): Promise<DynamicSettings> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        upsert: (id: string, input: DynamicSettings, userToken: string): Promise<DynamicSettings> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/${id}`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkReplace: (input: Array<DynamicSettings>, userToken: string): Promise<Array<DynamicSettings>> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        replace: (id: string, input: DynamicSettings, userToken: string): Promise<DynamicSettings> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/${id}`, input, {
                method: "PUT",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkModify: (input: MassModification<DynamicSettings>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/bulk`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modifyWithDiff: (id: string, input: Modification<DynamicSettings>, userToken: string): Promise<EntryChange<DynamicSettings>> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/${id}/delta`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        modify: (id: string, input: Modification<DynamicSettings>, userToken: string): Promise<DynamicSettings> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/${id}`, input, {
                method: "PATCH",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        bulkDelete: (input: Condition<DynamicSettings>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/bulk-delete`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        delete: (id: string, userToken: string): Promise<void> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/${id}`, undefined, {
                method: "DELETE",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => undefined)
        },
        count: (input: Condition<DynamicSettings>, userToken: string): Promise<number> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupCount: (input: GroupCountQuery<DynamicSettings>, userToken: string): Promise<Record<string, number>> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/group-count`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        aggregate: (input: AggregateQuery<DynamicSettings>, userToken: string): Promise<number | null | undefined> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
        groupAggregate: (input: GroupAggregateQuery<DynamicSettings>, userToken: string): Promise<Record<string, number | null | undefined>> => {
            return apiCall(`${this.httpUrl}/dynamic-settings/rest/group-aggregate`, input, {
                method: "POST",
                headers: userToken ? { ...this.extraHeaders, "Authorization": `Bearer ${userToken}` } : this.extraHeaders,
            }, 
            ).then(x => x.json())
        },
    }
}

