import {makeObjectModification} from "@lightningkite/lightning-server-simplified"
import {ArrowBack, Check} from "@mui/icons-material"
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Stack,
  Step,
  StepButton,
  StepContent,
  Stepper,
  Typography
} from "@mui/material"
import {Application} from "api/sdk"
import {AuthContext} from "App"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import Loading from "components/Loading"
import React, {FC, useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {getEmptyApplication} from "utils/helpers/helpers"
import {getLastCompletedStep, StepIndex, steps} from "utils/helpers/stepHelpers"

export interface FormStepContentProps {
  application: Application
  saveProgress: (newValues: Partial<Application>) => Promise<void>
  nextStep: () => void
  refreshApplication: () => Promise<void>
  requiresLicenseInfo?: boolean
}

const UserApplicationPage: FC = () => {
  const {applicationId, requiresLicenseInfo} = useParams()

  const {session, currentUser, logout} = useContext(AuthContext)

  const [lastCompletedStep, setLastCompletedStep] = useState<
    StepIndex | undefined
  >()
  const [activeStep, setActiveStep] = useState<StepIndex | undefined>()
  const [applications, setApplications] = useState<
    Application[] | null | undefined
  >()
  const [currentApplication, setCurrentApplication] =
    useState<Application | null>(null)

  const navigate = useNavigate()


  const saveProgress = async (newValues: Partial<Application>) => {
    if (!currentApplication) {
      throw new Error("Application is not started")
    }

    const modification = makeObjectModification(currentApplication, newValues)

    if (modification === null) {
      setCurrentApplication({...currentApplication})
      return
    }
    await session.application
      .modify(currentApplication._id, modification)
      .then((a) => setCurrentApplication(a))
  }

  const refreshApplication = async (): Promise<void> => {
    if (!currentApplication) {
      throw new Error("Application is not started")
    }

    await session.application
      .detail(currentApplication._id)
      .then(setCurrentApplication)
      .catch(() => setCurrentApplication(null))
  }

  const nextStep = () => {
    if (activeStep === undefined) {
      throw new Error("Active step is not initialized")
    }

    setActiveStep(activeStep + 1)
  }

  useEffect(() => {
      session.application
        .query({
          condition: {_id: {Equal: applicationId ?? ""}}
        })
        .then((applications) => {
          setApplications(applications)
          setCurrentApplication(applications[0])
        })
        .catch((error) => {
          setCurrentApplication(null)
          console.log(error)
        })
  }, [])

  useEffect(() => {
    if (!currentApplication) {
      return
    }
    const lastCompletedStep = getLastCompletedStep(currentApplication) ?? -1
    setLastCompletedStep(lastCompletedStep)

    if (activeStep === undefined) {
      setActiveStep(lastCompletedStep + 1)
    }
  }, [currentApplication])

  useEffect(() => {
    if (applications?.length && currentApplication === null) {
      setCurrentApplication(applications[0])
    }
  }, [applications])

  return (
    <>
      <Stack direction="row" spacing={2} sx={{mb: 2}}>
        <Button onClick={() => navigate("/job-postings")}>
          <ArrowBack />
          Back
        </Button>
        <Button onClick={logout}>Log Out</Button>
      </Stack>

      <Typography variant="h1" mb={4}>
        GStyle Employment Application
      </Typography>

      {(() => {
        if (applications === null) {
          return (
            <Alert severity="error" sx={{mt: 4}}>
              <AlertTitle>Error Loading Your Applications</AlertTitle>
              Please refresh the page or try again later.
            </Alert>
          )
        }

        if (!currentApplication) {
          return (
           <Loading />
          )
        }

        if (lastCompletedStep === undefined || activeStep === undefined) {
          return <Loading />
        }

        return (
          <Stepper
            nonLinear
            activeStep={activeStep}
            orientation="vertical"
            sx={{mt: 4}}
          >
            {Object.values(steps).map((step, index: StepIndex) => {
              return (
                <Step key={index} completed={index <= lastCompletedStep}>
                  <StepButton
                    onClick={() => setActiveStep(index)}
                    optional={index === activeStep ? step.subtitle : undefined}
                  >
                    <Typography variant="h2">{step.title}</Typography>
                  </StepButton>
                  <StepContent>
                    <Card sx={{mt: 2, maxWidth: 650}}>
                      <CardContent>
                        <step.content
                          saveProgress={saveProgress}
                          application={currentApplication}
                          refreshApplication={refreshApplication}
                          nextStep={nextStep}
                          requiresLicenseInfo={requiresLicenseInfo === "true"}
                        />
                      </CardContent>
                    </Card>
                  </StepContent>
                </Step>
              )
            })}
          </Stepper>
        )
      })()}
    </>
  )
}

export default UserApplicationPage
