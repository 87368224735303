import {Card, CardContent, Container} from "@mui/material"
import {JobPosting} from "api/sdk"
import {AuthContext} from "App"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import PageHeader from "components/PageHeader"
import React, {FC, useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import JobPostingForm from "./JobPostingForm"
export const AdminJobPostingDetail: FC = () => {
  const {session} = useContext(AuthContext)
  const {jobPostingId} = useParams()
  const navigate = useNavigate()

  const [jobPosting, setJobPosting] = useState<JobPosting | null>()

  if (jobPostingId === undefined) {
    throw new Error("User ID is undefined")
  }

  const refreshJobPosting = async () => {
    try {
      const jobPosing = await session.jobPosting.detail(jobPostingId)
      setJobPosting(jobPosing)
    } catch {
      setJobPosting(null)
    }
  }

  useEffect(() => {
    refreshJobPosting()
  }, [jobPostingId])

  const deleteJobPosting = async () => {
    const shouldDelete = confirm(
      "Are you sure you want to permanently delete this job posting?"
    )
    if (!shouldDelete) {
      return
    }
    await session.jobPosting
      .delete(jobPostingId)
      .then(() => navigate("/job-postings"))
      .catch(() => alert("Error deleting job posting"))
  }

  return (
    <Container maxWidth="md">
      <PageHeader
        title={jobPosting?.title ?? ""}
        breadcrumbs={[
          ["All Job Applications", "/job-postings"],
          [jobPosting?.title ?? "", ""]
        ]}
      >

      <AutoLoadingButton onClick={deleteJobPosting} color="error">
        Delete
      </AutoLoadingButton>
      </PageHeader>
      <Card>
        <CardContent>
          <JobPostingForm
            jobPosting={jobPosting === undefined ? null : jobPosting}
            refreshJobPosting = {refreshJobPosting}
          ></JobPostingForm>
        </CardContent>
      </Card>
    </Container>
  )
}
