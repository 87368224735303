import {makeFormikTextFieldProps} from "@lightningkite/mui-lightning-components"
import {ArrowDropDown} from "@mui/icons-material"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import { LoadingButton } from "@mui/lab"
import {Button, Stack, TextField, Typography} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers"
import {ConvictionInfo, CriminalInfo} from "api/sdk"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import {makeFormikRadioInputProps, RadioInput} from "components/RadioInput"
import dayjs from "dayjs"
import {FieldArray, FormikProvider, useFormik} from "formik"
import React, {FC, useEffect} from "react"
import {handleArrayValidationErrors} from "utils/helpers/helpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"

const validationSchema = yup.object().shape({
  convictionHistory: yup.array().of(
    yup.object().shape({
      dateConvicted: yup.date().nullable().typeError("Please input date as MM/DD/YYYY").required("Required"),
      stateOfViolation: yup.string().nullable().required("Required"),
      natureOfViolation: yup.string().nullable().required("Required"),
      penaltyComments: yup.string().nullable().required("Required")
    })
  ),
  beenDeniedExplain: yup
    .string()
    .nullable()
    .when("beenDenied", {
      is: "yes",
      then: yup.string().required("Required")
    }),
  beenRevokedExplain: yup
    .string()
    .nullable()
    .when("beenRevoked", {
      is: "yes",
      then: yup.string().required("Required")
    }),
  failedDrug: yup.string().required("Required"),
  completedReturnToDuty: yup.string().when("failedDrug", {
    is: "yes",
    then: yup.string().required("Required")
  })
})

type CriminalInfoInfoErrorKey = keyof CriminalInfo

const CriminalRecord: FC<FormStepContentProps> = (props) => {
  const {refreshApplication, application, nextStep, saveProgress} = props

  const [arrayErrors, setArrayErrors] = React.useState<
    ReturnType<typeof handleArrayValidationErrors<CriminalInfoInfoErrorKey>>
  >([])

  const blank: ConvictionInfo = {
    dateConvicted: null,
    stateOfViolation: null,
    natureOfViolation: null,
    penaltyComments: null
  }


  const formik = useFormik({
    initialValues: {
      convictionHistory: application.criminalInfo?.convictionHistory?.length
        ? application.criminalInfo.convictionHistory
        : new Array<ConvictionInfo>(),
      beenDenied:
        application.criminalInfo?.beenDeniedExplain === null ? "no" : "yes",
      beenDeniedExplain: application.criminalInfo?.beenDeniedExplain,
      beenRevoked:
        application.criminalInfo?.beenRevokedExplain === null ? "no" : "yes",
      beenRevokedExplain: application.criminalInfo?.beenRevokedExplain,
      failedDrug: application.criminalInfo?.failedDrug ? "yes" : "no",
      completedReturnToDuty: application.criminalInfo?.completedReturnToDuty
        ? "yes"
        : "no"
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      const temp: CriminalInfo = {
        convictionHistory: values.convictionHistory,
        beenDeniedExplain:
          values.beenDeniedExplain === undefined
            ? null
            : values.beenDeniedExplain,
        beenRevokedExplain:
          values.beenRevokedExplain === undefined
            ? null
            : values.beenRevokedExplain,
        failedDrug:
          typeof values.failedDrug === "string"
            ? values.failedDrug === "yes"
            : values.failedDrug,
        completedReturnToDuty:
          typeof values.completedReturnToDuty === "string"
            ? values.completedReturnToDuty === "yes"
            : values.completedReturnToDuty
      }

      saveProgress({
        ...application,
        criminalInfo: temp
      })
      nextStep()
    }
  })


  useEffect(() => {
    if (formik.submitCount === 0) return

    const errors = handleArrayValidationErrors<CriminalInfoInfoErrorKey>(
      formik.errors.convictionHistory
    )
    setArrayErrors(errors)
  }, [formik.errors, formik.submitCount])

  return (
    <FormikProvider value={formik}>
      <FormSection
        disableTopPadding
        title="Accident Record/Traffic Convictions for past Three Years"
      >
        <FieldArray name="convictionHistory">
          {({remove, push}) => (
            <>
              {formik.values.convictionHistory.map((conviction, index) => (
                <>
                <Stack direction="row"  justifyContent="space-between" >
                  <Typography variant="h3" sx={{mt: 1}}>
                    Conviction {index + 1}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{alignSelf: "flex-start"}}
                    onClick={() => remove(index)}
                    startIcon={<DeleteIcon />}
                  >
                    Remove Conviction Item
                  </Button>
                  </Stack>
                  <DatePicker
                    label="Date Convicted"
                    onChange={(e) => {
                      const date = dayjs(
                        e === null || e === undefined ? dayjs() : e
                      ).format("YYYY-MM-DD")
                      formik.setFieldValue(
                        `convictionHistory.${index}.dateConvicted`,
                        date
                      )
                    }}
                    value={formik.values.convictionHistory[index].dateConvicted}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          (arrayErrors[index] as ConvictionInfo) !== undefined
                            ? (arrayErrors[index] as ConvictionInfo)
                                .dateConvicted
                            : ""
                        }
                        error={
                          (arrayErrors[index] as ConvictionInfo) !== undefined
                            ? (arrayErrors[index] as ConvictionInfo)
                                .dateConvicted !== undefined
                            : false
                        }
                      />
                    )}
                    minDate={dayjs().subtract(120, "year")}
                    maxDate={dayjs()}
                  />
                  <TextField
                    label="State of Violation"
                    value={conviction.stateOfViolation ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `convictionHistory.${index}.stateOfViolation`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as ConvictionInfo) !== undefined
                        ? (arrayErrors[index] as ConvictionInfo)
                            .stateOfViolation
                        : ""
                    }
                    error={
                      (arrayErrors[index] as ConvictionInfo) !== undefined
                        ? (arrayErrors[index] as ConvictionInfo)
                            .stateOfViolation !== undefined
                        : false
                    }
                  />
                  <TextField
                    label="Nature of Violation"
                    value={conviction.natureOfViolation ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `convictionHistory.${index}.natureOfViolation`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as ConvictionInfo) !== undefined
                        ? (arrayErrors[index] as ConvictionInfo)
                            .natureOfViolation
                        : ""
                    }
                    error={
                      (arrayErrors[index] as ConvictionInfo) !== undefined
                        ? (arrayErrors[index] as ConvictionInfo)
                            .natureOfViolation !== undefined
                        : false
                    }
                  />
                  <TextField
                    label="Penalty Comments"
                    value={conviction.penaltyComments ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `convictionHistory.${index}.penaltyComments`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as ConvictionInfo) !== undefined
                        ? (arrayErrors[index] as ConvictionInfo).penaltyComments
                        : ""
                    }
                    error={
                      (arrayErrors[index] as ConvictionInfo) !== undefined
                        ? (arrayErrors[index] as ConvictionInfo)
                            .penaltyComments !== undefined
                        : false
                    }
                  />

                </>
              ))}
              <Button
                variant="contained"
                sx={{mt: 2}}
                onClick={() => {
                  push(blank)
                }}
                startIcon={<AddIcon />}
              >
                Add Conviction
              </Button>
            </>
          )}
        </FieldArray>

        <RadioInput
          label="Have you ever been denied a license, permit or privilege to operate a motor vehicle?"
          options={[
            {label: "Yes", value: "yes"},
            {label: "No", value: "no"}
          ]}
          {...makeFormikRadioInputProps(formik, "beenDenied")}
        />
        {formik.values.beenDenied === "yes" && (
          <TextField
            label="Been Denied Explanation"
            {...makeFormikTextFieldProps(formik, "beenDeniedExplain")}
          />
        )}
        <RadioInput
          label="Has any license, permit or privilege ever been suspended or revoked?"
          options={[
            {label: "Yes", value: "yes"},
            {label: "No", value: "no"}
          ]}
          {...makeFormikRadioInputProps(formik, "beenRevoked")}
        />
        {formik.values.beenRevoked === "yes" && (
          <TextField
            label="Been Revoked Explanation"
            {...makeFormikTextFieldProps(formik, "beenRevokedExplain")}
          />
        )}
        <RadioInput
          label="Have you tested positive, or refused to test, on any pre-employment drug or alcohol test administered by an employer to which you applied for, but did not obtain, safety- sensitive transportation work covered by DOT agency drug and alcohol testing rules during the last two (2) years?"
          options={[
            {label: "Yes", value: "yes"},
            {label: "No", value: "no"}
          ]}
          {...makeFormikRadioInputProps(formik, "failedDrug")}
        />
        <RadioInput
          label="If you answered yes, can. you provide/obtain proof that you have successfully completed the DOT return-to-duty requirements?"
          options={[
            {label: "Yes", value: "yes"},
            {label: "No", value: "no"}
          ]}
          {...makeFormikRadioInputProps(formik, "completedReturnToDuty")}
        />
      </FormSection>
      <LoadingButton
          endIcon={<ArrowDropDown />}
          variant="contained"
          onClick={()=>{formik.submitForm()}}
          sx={{mt: 4}}
          disabled={!formik.dirty}
        >
          Save and Continue
        </LoadingButton>
    </FormikProvider>
  )
}
export default CriminalRecord
