import {
  Address,
  ApplicantInfo,
  Application,
  ConvictionInfo,
  CriminalInfo,
  ExperienceInfo,
  LicenseInfo,
  PastEmployerInfo,
  PriorResidence,
  User
} from "api/sdk"
import {FormikErrors} from "formik"
import {v4 as uuidv4} from "uuid"

export const getEmptyApplication = (
  owner: User,
  jobPostingId: string
): Application => ({
  _id: uuidv4(),
  owner: owner._id,
  jobPosting: jobPostingId,
  applicant: null,
  priorResidences: [],
  licenses: [],
  experienceInfo: null,
  criminalInfo: null,
  employmentHistory: [],
  prevJob49CFRPart40: null,
  drivingHistoryDisclosure: false,
  backgroundCheckDisclosure: false,
  requestMVRBackgroundCopy: false,
  additionalDocuments: [],
  signature: null,
  readyForReview: false,
  review: null,
  pdfs: []
})

export const emptyPersonalInfo: ApplicantInfo = {
  email: "",
  firstName: "",
  lastName: "",
  suffix: null,
  address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    country: null,
    postalCode: null
  },
  phone: "",
  socialSecurityNumber: "",
  birthday: "",
  licenseNumber: "",
  licensePicture: null,
  licensedStateIssued: null,
}

export const emptyResidency: PriorResidence = {
  address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    country: null,
    postalCode: null
  },
  startYear: null,
  endYear: null
}

export const emptyAddress: Address = {
  line1: null,
  line2: null,
  city: null,
  state: null,
  country: null,
  postalCode: null
}

export const emptyLicenseInfo: LicenseInfo = {
  state: "",
  licenseNumber: "",
  type: "",
  expDate: ""
}

export const emptyExperienceInfo: ExperienceInfo = {
  totalCdlExperience: null,
  straightTruck: null,
  tractorSemiTrailer: null,
  tractorTwoTrailer: null,
  other: null
}

export const emptyCriminalInfoRecord: CriminalInfo = {
  convictionHistory: [],
  beenDeniedExplain: null,
  beenRevokedExplain: null,
  failedDrug: null,
  completedReturnToDuty: null
}

export const emptyConvictionInfo: ConvictionInfo = {
  dateConvicted: null,
  stateOfViolation: null,
  natureOfViolation: null,
  penaltyComments: null
}

export const emptyEmploymentHistory: PastEmployerInfo = {
  name: null,
  phone: null,
  address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    country: null,
    postalCode: null
  },
  positionHeld: null,
  startDate: null,
  endDate: null,
  salary: 0,
  reasonForLeaving: null,
  subjectToFMCSR: null,
  gapExplain: null,
  designatedRepresentative: null
}

export const handleArrayValidationErrors = <Keys extends string>(
  formikError: string | string[] | FormikErrors<any>[] | undefined
): Array<Partial<Record<Keys, string>>> => {
  const errors: Array<Partial<Record<Keys, string>>> = []
  if (formikError === undefined || formikError === null) {
    return errors
  }
  if (Array.isArray(formikError)) {
    formikError.forEach((error) => {
      if (typeof error === "undefined") {
        errors.push({} as Partial<Record<Keys, string>>)
      }
      if (typeof error === "object") {
        errors.push(error as Partial<Record<Keys, string>>)
      }
    })
  }
  return errors
}

export const uploadBlobFile = (uploadUrl: string, file: File) =>
  fetch(uploadUrl, {
    method: "PUT",
    body: file,
    headers: {
      "Content-type": file.type,
      "x-ms-blob-type": "BlockBlob"
    }
  }).then((res) => {
    if (!res.ok) {
      console.log("Error uploading file", res)
      throw new Error("Error uploading document")
    }
  })
