import {ArrowDropDown} from "@mui/icons-material"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import {LoadingButton} from "@mui/lab"
import {Box, Button, MenuItem, Stack, TextField} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers"
import {LicenseInfo} from "api/sdk"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import dayjs from "dayjs"
import {FieldArray, FormikProvider, useFormik} from "formik"
import React, {FC, useEffect} from "react"
import {stateCodeOptions} from "utils/constants"
import {handleArrayValidationErrors} from "utils/helpers/helpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"

const validationSchema = yup.object().shape({
  licenses: yup.array().of(
    yup.object().shape({
      state: yup.string().required("Required"),
      licenseNumber: yup.string().required("Required"),
      type: yup.string().required("Required"),
      expDate: yup.date().typeError("Please input date as MM/DD/YYYY").required("Required")
    })
  )
})

type LicenseErrorKey = keyof LicenseInfo

const Licenses: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep} = props
  const [arrayErrors, setArrayErrors] = React.useState<
    ReturnType<typeof handleArrayValidationErrors<LicenseErrorKey>>
  >(application.licenses.map((x) => ({})))

  const blank: LicenseInfo = {
    state: "",
    licenseNumber: "",
    type: "",
    expDate: ""
  }

  const formik = useFormik({
    initialValues: {
      licenses:
        application.licenses.length === 0 ? [] : application.licenses
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      saveProgress({
        ...application,
        licenses: values.licenses
      })
      nextStep()
    }
  })

  useEffect(() => {
    if (formik.submitCount === 0) return

    const errors = handleArrayValidationErrors<LicenseErrorKey>(
      formik.errors.licenses
    )
    setArrayErrors(errors)
  }, [formik.errors, formik.submitCount])

  return (
    <>
      <FormikProvider value={formik}>
        <FieldArray name="licenses">
          {({insert, remove, push, replace}) => (
            <>
              {formik.values.licenses.map((license, index) => (
                <FormSection
                  key={index}
                  title={`License ${index + 1}`}
                  formAction={
                    <Button
                      variant="contained"
                      sx={{alignSelf: "flex-end"}}
                      onClick={() => remove(index)}
                      startIcon={<DeleteIcon />}
                    >
                      Remove License
                    </Button>
                  }
                  disableTopPadding={index === 0}
                >
                  <TextField
                    fullWidth
                    select
                    label="State"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `licenses.${index}.state`,
                        e.target.value
                      )
                    }}
                    value={formik.values.licenses[index].state}
                    helperText={
                      (arrayErrors[index] as LicenseInfo) !== undefined
                        ? (arrayErrors[index] as LicenseInfo).state
                        : ""
                    }
                    error={
                      (arrayErrors[index] as LicenseInfo) !== undefined
                        ? (arrayErrors[index] as LicenseInfo).state !==
                          undefined
                        : false
                    }
                  >
                    {stateCodeOptions.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    label="License Number"
                    value={formik.values.licenses[index].licenseNumber}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `licenses.${index}.licenseNumber`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as LicenseInfo) !== undefined
                        ? (arrayErrors[index] as LicenseInfo).licenseNumber
                        : ""
                    }
                    error={
                      (arrayErrors[index] as LicenseInfo) !== undefined
                        ? (arrayErrors[index] as LicenseInfo).licenseNumber !==
                          undefined
                        : false
                    }
                  />

                  <TextField
                    label="Type"
                    value={formik.values.licenses[index].type}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `licenses.${index}.type`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as LicenseInfo) !== undefined
                        ? (arrayErrors[index] as LicenseInfo).type
                        : ""
                    }
                    error={
                      (arrayErrors[index] as LicenseInfo) !== undefined
                        ? (arrayErrors[index] as LicenseInfo).type !== undefined
                        : false
                    }
                  />
                  <DatePicker
                    label="License Expiration Date"
                    onChange={(e) => {
                      const date = dayjs(
                        e === null || e === undefined ? dayjs() : e
                      ).format("YYYY-MM-DD")
                      formik.setFieldValue(`licenses.${index}.expDate`, date)
                    }}
                    value={formik.values.licenses[index].expDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          (arrayErrors[index] as LicenseInfo) !== undefined
                            ? (arrayErrors[index] as LicenseInfo).expDate
                            : ""
                        }
                        error={
                          (arrayErrors[index] as LicenseInfo) !== undefined
                            ? (arrayErrors[index] as LicenseInfo).expDate !==
                              undefined
                            : false
                        }
                      />
                    )}
                    minDate={dayjs().subtract(120, "year")}
                    maxDate={dayjs().add(120,"year")}
                  />
                </FormSection>
              ))}
              <Box >
                <Button
                  variant="contained"
                  sx={{mt: 2}}
                  onClick={() => {
                    push(blank)
                  }}
                  startIcon={<AddIcon />}
                >
                  Add License
                </Button>
              </Box>
            </>
          )}
        </FieldArray>
        {/* <Stack>
          <AutoLoadingButton
            endIcon={<ArrowDropDown />}
            variant="contained"
            onClick={formik.submitForm}
            sx={{mt: 4}}
          >
            Save and Continue
          </AutoLoadingButton>
        </Stack> */}
        <LoadingButton
          endIcon={<ArrowDropDown />}
          variant="contained"
          loading={formik.isSubmitting}
          onClick={() => {
            formik.submitForm()
          }}
          disabled={!formik.dirty}
          sx={{mt: 4}}
        >
          Save and Continue
        </LoadingButton>
      </FormikProvider>
    </>
  )
}
export default Licenses
