import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText
} from "@mui/material"
import {FormikValues, useFormik} from "formik"
import React, {ChangeEvent, FC} from "react"

export interface CheckboxProps {
  label?: string
  name: string
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  helperText?: string
  error?: boolean
}

export const CheckboxInput: FC<CheckboxProps> = (props) => {
  const {label, helperText, checked, onChange, error, name} = props

  return (
    <FormControl error={error}>
      <FormControlLabel
        label={label}
        control={<Checkbox name={name} checked={checked} onChange={onChange} />}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export function makeFormikCheckboxProps<T extends FormikValues>(
  formik: ReturnType<typeof useFormik<T>>,
  field: keyof T
): CheckboxProps {
  return {
    name: field.toString(),
    checked: !!formik.values[field],
    onChange: formik.handleChange,
    error: formik.touched[field] && !!formik.errors[field],
    helperText: formik.touched[field] && (formik.errors[field] as string)
  }
}
