import {faker} from "@faker-js/faker"
import {Address, Application, User} from "api/sdk"
export function generateApplicationsForUsers(users: User[]): Application[] {
  return users.map((user) => ({
    _id: faker.datatype.uuid(),
    owner: user._id,
    jobPosting: "",
    applicant: null,
    priorResidences: [],
    licenses: [],
    experienceInfo: null,
    criminalInfo: null,
    employmentHistory: [],
    prevJob49CFRPart40: false,
    drivingHistoryDisclosure: false,
    backgroundCheckDisclosure: false,
    requestMVRBackgroundCopy: false,
    additionalDocuments: [],
    signature: faker.image.avatar(),
    readyForReview: false,
    review: null,
    pdfs:[],
    ipAddress :"0.0.0.0.0"
  }))
}


function generateAddress(): Address {
  const twoLines = faker.datatype.boolean()

  return {
    line1: faker.address.streetAddress(!twoLines),
    line2: twoLines ? faker.address.secondaryAddress() : null,
    city: faker.address.city(),
    state: faker.address.stateAbbr(),
    country: "United States",
    postalCode: faker.address.zipCode()
  }
}

