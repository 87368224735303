import {RestDataTable} from "@lightningkite/mui-lightning-components"
import AddIcon from "@mui/icons-material/Add"
import {Container, Dialog, DialogContent, DialogTitle, Fab} from "@mui/material"
import {AuthContext} from "App"
import PageHeader from "components/PageHeader"
import React, {FC, useContext} from "react"
import {useNavigate} from "react-router-dom"
import JobPostingForm from "./JobPostingForm"

const AdminJobPostingIndex: FC = () => {
  const navigate = useNavigate()
  const {session} = useContext(AuthContext)

  const [openAddJobPostingDialog, setOpenAddJobPostingDialog] =
    React.useState(false)

  return (
    <Container maxWidth="md">
      <PageHeader title="Job Postings" />
      <Fab
        color="primary"
        aria-label="add"
        sx={{margin: 2}}
        onClick={() => setOpenAddJobPostingDialog(true)}
      >
        <AddIcon />
      </Fab>
      <RestDataTable
        restEndpoint={session.jobPosting}
        onRowClick={(job) => navigate(`/job-postings/${job._id}`)}
        columns={[
          {
            field: "title",
            headerName: "Title",
            flex: 1
          },
          {
            field: "description",
            headerName: "Description",
            flex: 1
          },
          {
            field: "published",
            headerName: "Published",
            type: "boolean"
          }
        ]}
      />
      <Dialog
        open={openAddJobPostingDialog}
        onClose={() => setOpenAddJobPostingDialog(false)}
      >
        <DialogTitle>Add Job Posting</DialogTitle>
        <DialogContent>
          <JobPostingForm
            jobPosting={null}
            onFinished={() => setOpenAddJobPostingDialog(false)}
          ></JobPostingForm>
        </DialogContent>
      </Dialog>
    </Container>
  )
}

export default AdminJobPostingIndex
