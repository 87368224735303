import {generateApplicationsForUsers} from "./mocks/applications"
import {generateJobPostings} from "./mocks/jobPostings"
import { generateSignatureInfo } from "./mocks/signatureInfo"
import {generateUsers} from "./mocks/users"
import {Application, JobPosting, SignatureInfo, User} from "./sdk"

export interface MockDatastore {
  users: User[]
  applications: Application[]
  jobPosting: JobPosting[],
  signatureInfo: SignatureInfo[]
}

export const generateMockDatastore = (): MockDatastore => {
  const users = generateUsers(25)
  const applications = generateApplicationsForUsers(users)
  const jobPosting = generateJobPostings(10)
  const signatureInfo = generateSignatureInfo(10,applications)
  return {users, applications, jobPosting, signatureInfo}
}
