import {makeFormikTextFieldProps} from "@lightningkite/mui-lightning-components"
import {ArrowDropDown} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {Alert, Button, TextField} from "@mui/material"
import { MockApi } from "api/mockApi"
import { LiveApi } from "api/sdk"
import { AuthContext, UnauthContext } from "App"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import { CheckboxInput, makeFormikCheckboxProps } from "components/CheckboxInput"
import FormSection from "components/FormSection"
import {useFormik} from "formik"
import { resolve } from "path"
import React, {FC, useContext, useEffect} from "react"
import { LocalStorageKey } from "utils/constants"
import { isFormComplete } from "utils/helpers/stepHelpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"

const validationSchema = yup.object().shape({
  signature: yup.string().typeError("Required").required("Required"),
  digitalConsent: yup.boolean().required("Required")
})

const Signature: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep} = props
  const [submitted, setSubmitted] = React.useState(
    !(application.signature === null || application.signature === undefined)
  )
  const [error, setError] = React.useState<String | null>(null)
  const {api} = useContext(UnauthContext)
  const {session} = useContext(AuthContext)
  const selectedBackendURL = (api as LiveApi | MockApi).httpUrl

  const formik = useFormik({
    initialValues: {
      signature: application.signature ?? null,
      digitalConsent: false
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      if (isFormComplete(application) && values.digitalConsent) {
        fetch(`${localStorage.getItem(
          LocalStorageKey.BACKEND_URL)??""}/application/sign/${application._id}/${values.signature??""}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(LocalStorageKey.USER_TOKEN)??""}`, 
          }
        })
        .then((result) => {
          if (result.status === 200) {
            setSubmitted(true)
            formik.setSubmitting(false)
          }
        })
        .catch(() => {
          setError("There was an error submitting your application. Please try again.")
        })
      } else {
        setError("Please complete all steps before submitting your application.")
        formik.setSubmitting(false)
      }
    }
  })
  return (
    <>
    <FormSection title="Signature" disableTopPadding
    subtitle={
      <>
      To be Read and Signed by Applicant<br /><br />
    In the event of employment, I understand that false or misleading information given in my application or interview may result in discharge. I understand, also, that I am required to abide by all rules and regulations of the company.
    <br/><br />
    I hereby authorize E‐Style Transport, LLC and its designated agents and representatives to conduct a comprehensive review of my background as condition of my employment with E‐Style Transport, LLC. I understand that the scope of the background investigation may include, but not limited to the following areas: verification of social security number, credit reports, current and previous residences; employment history, education background, character references; drug testing, civil and criminal history records from any criminal justice agency in any or all federal, state, county jurisdictions; driving records, birth records, and any other public or private records.
    <br />
    <br />
    I further authorize any individual, company , firm, corporation, or public agency to divulge any and all information, verbal or written, pertaining to me, to E‐Style Transport, LLC, it’s leadership/management team, and/or its agents as necessary in evaluating my qualifications for employment. I further authorize the complete release of any records or data pertaining to me which the individual, company, firm, corporation, or public agency may have, to include information or data received from other sources. Including: verification of social security number, credit reports, current and previous residences; employment history, education background, character references; drug testing, civil and criminal history records from any criminal justice agency in any or all federal, state, county jurisdictions; driving records, birth records, and any other public or private records. E‐Style Transport, LLC and its designated agents and representatives shall maintain all information received from this authorization in a confidential manner in order to protect the applicant’s personal information.
    “I understand that information I provide regarding current and/or previous employers may be used, and those employer(s) will be contacted, for the purpose of investigating my safety performance history as required by 49 CFR 391.23(d) and (e). I understand that I have the right to: Review information provided by current/previous employers Have errors in the information corrected by previous employers and for those previous employers to resend the corrected information to the prospective employer; and Have a rebuttal statement attached to the alleged erroneous information, if the previous employer(s) and I cannot agree on the accuracy of the information.
    In the event of employment, I understand that false or misleading information given in my application or interview
may result in discharge. I understand, also, that I am required to abide by all rules and regulations of the company.
I hereby authorize E‐Style Transport, LLC and its designated agents and representatives to conduct a
comprehensive review of my background as condition of my employment with E‐Style Transport, LLC. I understand
that the scope of the background investigation may include, but not limited to the following areas: verification of
social security number, credit reports, current and previous residences; employment history, education
background, character references; drug testing, civil and criminal history records from any criminal justice agency
in any or all federal, state, county jurisdictions; driving records, birth records, and any other public or private
records.
<br/>
<br />
I further authorize any individual, company , firm, corporation, or public agency to divulge any and all information,
verbal or written, pertaining to me, to E‐Style Transport, LLC, it’s leadership/management team, and/or its agents
as necessary in evaluating my qualifications for employment. I further authorize the complete release of any
records or data pertaining to me which the individual, company, firm, corporation, or public agency may have, to
include information or data received from other sources. Including: verification of social security number, credit
reports, current and previous residences; employment history, education background, character references; drug
testing, civil and criminal history records from any criminal justice agency in any or all federal, state, county
jurisdictions; driving records, birth records, and any other public or private records. E‐Style Transport, LLC and its
designated agents and representatives shall maintain all information received from this authorization in a
confidential manner in order to protect the applicant’s personal information.
<br/>
<br />
“I understand that information I provide regarding current and/or previous employers may be used, and those
employer(s) will be contacted, for the purpose of investigating my safety performance history as required by 49
CFR 391.23(d) and (e). I understand that I have the right to:
<br/>
<ul>
  <li>Review information provided by current/previous employers</li>
  <li>Have errors in the information corrected by previous employers and for those previous employers to re‐
send the corrected information to the prospective employer; and</li>
<li>Have a rebuttal statement attached to the alleged erroneous information, if the previous employer(s) and
I cannot agree on the accuracy of the information.”</li>
</ul>
    </>}
    formAction={
      <Button
      variant="contained"
      onClick={() => {
        window.history.back()
      }}
      >
      Leave
    </Button>
    }
    >

      {!submitted && (
        <>
          <TextField
            fullWidth
            label="Sign Here"
            {...makeFormikTextFieldProps(formik, "signature")}
          />
          <CheckboxInput
            label="By checking this box, I consent to digitally sign this application."
            {...makeFormikCheckboxProps(formik, "digitalConsent")}
          />

        </>
      )}
      {submitted && (
        <Alert severity="success">Your application has been submitted!</Alert>
      )}
      {error && (
        <Alert severity="error">
          {error}
        </Alert>
      )}
    </FormSection>

              <LoadingButton
              endIcon={<ArrowDropDown />}
              loading={formik.isSubmitting}
              variant="contained"
              onClick={() => {formik.submitForm()}}
              disabled={submitted}
              sx={{mt: 4}}
            >
              Save and Continue
            </LoadingButton>

            </>
  )
}

export default Signature
