import {faker} from "@faker-js/faker"
import {Application, SignatureInfo} from "api/sdk"

export function generateSignatureInfo(total: number, applications:Application[]): SignatureInfo[] {
  return Array.from({length: total}, () => ({
    _id: faker.datatype.uuid(),
    application: applications[faker.datatype.number(applications.length-1)],
    timestamp : faker.date.past().toISOString(),
    emailAtSigning: "",
    ipAddress: "",
  }))
}
