/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { apiCall } from "@lightningkite/lightning-server-simplified"
import { Label } from "@mui/icons-material"
import {Alert, Box, Button, Typography} from "@mui/material"
import {Address, Application, User} from "api/sdk"
import FormSection from "components/FormSection"
import { LabeledValue } from "components/LabeledValue"
import React, {FC, useEffect, useState} from "react"
import FileViewer from "react-file-viewer-plugins-v2"

function addressRenderer(address: Address) {
  return (
    <Box>
      {address.line1 && (
        <Typography variant="body1">{address.line1}</Typography>
      )}
      {address.line2 && (
        <Typography variant="body1">{address.line2}</Typography>
      )}
      <Typography variant="body1">
        {`${address.city ?? "–"}, ${address.state ?? "–"} ${
          address.postalCode ?? "–"
        }`}
      </Typography>
    </Box>
  )
}

enum FileType {
  PDF = "application/pdf",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
}


function dateRenderer(date: string) {
  return (
    <Typography variant="body1">
      {new Date(date).toLocaleDateString()}
    </Typography>
  )
}

function dateTimeRenderer(date: string) {
  return (
    <Typography variant="body1">{new Date(date).toLocaleString()}</Typography>
  )
}

export interface ApplicationPreviewProps {
  application: Application
  user: User
}

export const ApplicationPreview: FC<ApplicationPreviewProps> = ({
  application,
  user
}) => {

  const [files, setFiles] = useState<{
    type: string | null
    url: string
  }[]>([])

  const getFiles = () => {
    const pdfsAndOtherDocs = [...application?.pdfs ?? [], ...application.additionalDocuments]
    console.log("pdfsAndOtherDocs length", pdfsAndOtherDocs?.length)

    const promises: Promise<unknown>[] = []

    pdfsAndOtherDocs?.forEach((fileUrl) => {
      console.log("in the for each")
      promises.push( new Promise((resolve, reject) => {
      // S3 doesn't support HEAD requests so we have to use GET requests that returns range of 0 bytes to mimic head request
      fetch(fileUrl, {
        method: "GET",
        headers: {
          Range: "bytes=0-0" // Range of 0 bytes to mimic HEAD request
        }
      })
      .then(resolve)
      })
      )
    })
    return promises
  }


  
  useEffect(() => {
    const temp:{
      type: string | null
      url: string
    }[] =[]
    const promises = getFiles()
    Promise.all(promises).then((res) => {
      setFiles([])
      console.log("res", res)
      res.forEach((response:any) => {
        temp.push({type: response.headers.get("Content-Type"), url: response.url})
      })
      setFiles(temp)
    })
}, [])


  return (
    <>
      <Typography variant="h2" sx={{mb: 3}}>
        Application
      </Typography>

      <FormSection title="Applicant" spacing={2} disableTopPadding>
        {!application.applicant ? (
          <Typography variant="body1" fontStyle="italic">
            Applicant info not filled out
          </Typography>
        ) : (
          <>
            <LabeledValue
              label="Name"
              value={`${application.applicant.firstName} ${application.applicant.lastName}`}
            />
            <LabeledValue label="Email" value={user.email} />
            <LabeledValue label="Phone" value={application.applicant.phone} />
            <LabeledValue label="Birthday" value={application.applicant.birthday} />
            <LabeledValue label="Social Security Number" value={application.applicant.socialSecurityNumber} />
            <LabeledValue label="Address" value={application.applicant.address} valueRenderer={addressRenderer} />
            <LabeledValue label="License Number" value={application.applicant.licenseNumber} />
            <img src={application.applicant.licensePicture??""} />
          </>
        )}
      </FormSection>
      
      <FormSection title="Prior Residence" spacing={2}>
        {application.priorResidences.length===0 ? (
          <Typography variant="body1" fontStyle="italic">
            No prior residences
          </Typography>
        ) : (
          <>
          { application.priorResidences.map((residence, index) => (
            <>
          <Typography variant="body1" >
            Prior Residence {index+1}
          </Typography> 
          <LabeledValue label="Address" value={residence.address} valueRenderer={addressRenderer} />
          <LabeledValue label="Years at address" value={(residence.endYear??0) - (residence.startYear??0)} />
          </>
          ))}
          </>
        )}
      </FormSection>

      <FormSection title="License" spacing={2}>
        {application.licenses.length===0 ? (
          <Typography variant="body1" fontStyle="italic">
            No licenses
          </Typography>
        ) : (
          <>
          { application.licenses.map((license, index) => (
            <>
          <Typography variant="h6" >
            License {index+1}
          </Typography>
          <LabeledValue label="State" value={license.state} />
          <LabeledValue label="License Number" value={license.licenseNumber} />
          <LabeledValue label="License Type" value={license.type} />
          <LabeledValue label="Expiration Date" value={license.expDate} />
        </>
          ))}
          </>
        )}
      </FormSection>

      <FormSection title="Experience Info" spacing={2}>
        {!application.experienceInfo ? (
          <Typography variant="body1" fontStyle="italic">
            Experience info not filled out
          </Typography>
        ) : (
          <>
          <LabeledValue label="Total CDL Experience" value={application.experienceInfo.totalCdlExperience} />
          {application.experienceInfo.straightTruck &&(
            <>
            <Typography variant="h6" >
            Straight Truck
          </Typography>
          <LabeledValue label="Equipment Type" value={application.experienceInfo.straightTruck.equipmentType??""} />
          <LabeledValue label="Start Date" value={application.experienceInfo.straightTruck.startDate??""} />
          <LabeledValue label="End Date" value={application.experienceInfo.straightTruck.endDate??""} />
          <LabeledValue label="Approximate Miles" value={application.experienceInfo.straightTruck.approxMiles??""}/>
          </>
          )}
          {application.experienceInfo.tractorSemiTrailer &&(
            <>
            <Typography variant="h6" >
            Tractor Semi Trailer
          </Typography>
          <LabeledValue label="Equipment Type" value={application.experienceInfo.tractorSemiTrailer.equipmentType??""} />
          <LabeledValue label="Start Date" value={application.experienceInfo.tractorSemiTrailer.startDate??""} />
          <LabeledValue label="End Date" value={application.experienceInfo.tractorSemiTrailer.endDate??""} />
          <LabeledValue label="Approximate Miles" value={application.experienceInfo.tractorSemiTrailer.approxMiles??""}/>
          </>
          )}
          {application.experienceInfo.tractorTwoTrailer &&(
            <>
            <Typography variant="h6" >
            Tractor Two Trailer
          </Typography>
          <LabeledValue label="Equipment Type" value={application.experienceInfo.tractorTwoTrailer.equipmentType??""} />
          <LabeledValue label="Start Date" value={application.experienceInfo.tractorTwoTrailer.startDate??""} />
          <LabeledValue label="End Date" value={application.experienceInfo.tractorTwoTrailer.endDate??""} />
          <LabeledValue label="Approximate Miles" value={application.experienceInfo.tractorTwoTrailer.approxMiles??""}/>
          </>
          )}
          {application.experienceInfo.other &&(
            <>
            <Typography variant="h6">
            Other
          </Typography>
          <LabeledValue label="Equipment Type" value={application.experienceInfo.other.equipmentType??""} />
          <LabeledValue label="Start Date" value={application.experienceInfo.other.startDate??""} />
          <LabeledValue label="End Date" value={application.experienceInfo.other.endDate??""} />
          <LabeledValue label="Approximate Miles" value={application.experienceInfo.other.approxMiles??""}/>
          </>
          )}
          </>
          )}
      </FormSection>

      <FormSection title="Criminal Info" spacing={2}>
        {!application.criminalInfo ? (
          <Typography variant="body1" fontStyle="italic">
            Criminal info not filled out
          </Typography>
        ) : (
          <>
          <Typography variant="h6" >
            Convictions History
          </Typography>
          {application.criminalInfo.convictionHistory.length===0 ? (
            <Typography variant="body1" fontStyle="italic">
              No convictions
              </Typography>
              ) : (
                <>
                { application.criminalInfo.convictionHistory.map((conviction, index) => (
                  <>
                  <Typography variant="h6" >
                    Conviction {index+1}
                  </Typography>
                  <LabeledValue label="Conviction Date" value={conviction.dateConvicted} />
                  <LabeledValue label="stateOfConviction" value={conviction.stateOfViolation} />
                  <LabeledValue label="natureOfConviction" value={conviction.natureOfViolation} />
                  <LabeledValue label="penaltyComments" value={conviction.penaltyComments} />
                  </>
                ))}
                </>
              )}
              
              
              <LabeledValue label = "Been Denied Explained" value={application.criminalInfo.beenDeniedExplain??"Not applicable"} />
    
              <LabeledValue label = "Been Revoked Explained" value={application.criminalInfo.beenRevokedExplain??"Not applicable"} />
              
              <LabeledValue label="Failed Drug Test" value={application.criminalInfo.failedDrug?"True":"False"} />
              <LabeledValue label="Completed Return to Duty" value={application.criminalInfo.completedReturnToDuty?"True":"False"} />

              </>
          )}
      </FormSection>

      <FormSection title="Employment History" spacing={2}>
        {application.employmentHistory.length===0 ? (
          <Typography variant="body1" fontStyle="italic">
            No employment history
            </Typography>
            ) : (
              <>
              { application.employmentHistory.map((employment, index) => (
                <>
                <Typography variant="h6" >
                  Employment {index+1}
                </Typography>
                <LabeledValue label="Employer Name" value={employment.name} />
                <LabeledValue label="Employer Address" value={employment.address} valueRenderer={addressRenderer} />
                <LabeledValue label="Employer Phone" value={employment.phone} />
                <LabeledValue label="Position Held" value={employment.positionHeld} />
                <LabeledValue label="Start Date" value={employment.startDate} />
                <LabeledValue label="End Date" value={employment.endDate} />
                <LabeledValue label="Reason for Leaving" value={employment.reasonForLeaving} />
                <LabeledValue label="GAP Explain" value={employment.gapExplain} />
                <LabeledValue label="Designated Representative" value={employment.designatedRepresentative} />
                </>
              ))}
              <LabeledValue label="Previous Job 49 CFR part 40" value={application.prevJob49CFRPart40?"True":"False"} />
              </>
            )}
      </FormSection>
      
      <FormSection title="Aggreements" spacing={2}>
              <LabeledValue label="Driving History Disclosure" value={application.drivingHistoryDisclosure?"True":"False"} />
              <LabeledValue label="Background Check Disclosure" value={application.backgroundCheckDisclosure?"True":"False"} />
              <LabeledValue label="requestMVRBackgroundCopy" value={application.requestMVRBackgroundCopy?"True":"False"} />
      </FormSection>
      
      <FormSection title="signature" spacing={2}>
              <LabeledValue label="Signature" value={application.signature} />
            </FormSection>

      <FormSection title="Documents" spacing={2}
      >
        {files.length > 0 ?(
          <>
          <p>{files.length}</p>
          {files.map((file, index) => (
            <>
             { file.type === FileType.PDF  && (
              <>
            <iframe src={file.url} width="100%" height="700px" key={index} />
            <a href={file.url} target="_blank" rel="noreferrer">Download File</a>
            </>
          )} 
          { file.type === FileType.DOCX  && (
            <>
            <FileViewer
             // One of these types: https://www.npmjs.com/package/react-file-viewer
            fileType="docx"
            filePath={file.url}
              />
              <a href={file.url} target="_blank" rel="noreferrer">Download File</a>
              </>
            )}
            { file.type==null && (
              <>
              <Alert severity="warning">Error previewing documents</Alert>
                <a href={file.url} target="_blank" rel="noreferrer">Download File</a>
                </>
            )}
            </>
          ))}
          </>
        ): (
          <Typography variant="body1" fontStyle="italic">
            no documents
            </Typography>
            )
        }
      </FormSection>

    </>
  )
}
