import {makeObjectModification} from "@lightningkite/lightning-server-simplified"
import {makeFormikTextFieldProps} from "@lightningkite/mui-lightning-components"
import {LoadingButton} from "@mui/lab"
import {Alert, Button, Stack, TextField} from "@mui/material"
import {JobPosting} from "api/sdk"
import {AuthContext} from "App"
import {useFormik} from "formik"
import React, {useContext, useEffect, useState} from "react"
import {v4 as uuidv4} from "uuid"
import {
  CheckboxInput,
  makeFormikCheckboxProps
} from "../../components/CheckboxInput"

export interface JobPostingFormProps {
  jobPosting: JobPosting | null
  refreshJobPosting?: () => Promise<void>
  onFinished?: () => void
}

const JobPostingForm = (props: JobPostingFormProps) => {
  const {jobPosting, onFinished, refreshJobPosting} = props
  const {session} = useContext(AuthContext)
  const [error, setError] = useState<string | undefined>(undefined)

  const formik = useFormik({
    initialValues: {
      title: jobPosting?.title ?? "",
      positionsAvailable: jobPosting?.positionsAvailable ?? 0,
      published: jobPosting?.published ?? false,
      description: jobPosting?.description ?? "",
      salaryRangeBegin: jobPosting?.salaryRangeBegin ?? 0,
      salaryRangeEnd: jobPosting?.salaryRangeEnd ?? 0,
      requiresLicenseInfo: jobPosting?.requiresLicenseInfo ?? false
    },
    onSubmit: async (values, {resetForm}) => {
      const jobPostingToUpdateAdd: Partial<JobPosting> = {
        _id: jobPosting?._id ?? uuidv4(),
        title: values.title,
        positionsAvailable: values.positionsAvailable,
        published: values.published,
        description: values.description,
        salaryRangeBegin: values.salaryRangeBegin,
        salaryRangeEnd: values.salaryRangeEnd,
        requiresLicenseInfo: values.requiresLicenseInfo
      }

      if (jobPosting) {
        const modification = makeObjectModification(
          jobPosting,
          jobPostingToUpdateAdd
        )
        await session.jobPosting.modify(jobPosting._id, modification)
        if (refreshJobPosting) {
          await refreshJobPosting()
        }
        //   .then((a) => setCurrentApplication(a))
      } else {
        await session.jobPosting
          .insert(jobPostingToUpdateAdd as JobPosting)
          .then(() => {
            if (onFinished) {
              onFinished()
            }
          })
        if (refreshJobPosting) {
          await refreshJobPosting()
        }
      }
    }
  })

  useEffect(() => {
    if (jobPosting) {
      formik.resetForm({values: jobPosting})
    }
  }, [jobPosting])

  return (
    <>
      <Stack spacing={2}>
        <TextField
          label="Title"
          {...makeFormikTextFieldProps(formik, "title")}
        />

        <TextField
          label="Description"
          multiline
          {...makeFormikTextFieldProps(formik, "description")}
        />

        <TextField
          label="Positions available"
          {...makeFormikTextFieldProps(formik, "positionsAvailable")}
        />

        <TextField
          label="Salary Range Begin"
          {...makeFormikTextFieldProps(formik, "salaryRangeBegin")}
        />
        <TextField
          label="Salary Range End"
          {...makeFormikTextFieldProps(formik, "salaryRangeEnd")}
        />
        <CheckboxInput
          label="Requires CDL Drivers License"
          {...makeFormikCheckboxProps(formik, "requiresLicenseInfo")}
        />
        <CheckboxInput
          label="Published"
          {...makeFormikCheckboxProps(formik, "published")}
        />
      </Stack>
      <LoadingButton
        variant="contained"
        loading={formik.isSubmitting}
        style={{alignSelf: "end"}}
        disabled={!formik.dirty}
        onClick={() => {
          formik.submitForm()
        }}
      >
        {jobPosting !== null ? "Update" : "Add"}
      </LoadingButton>
      {error && <Alert severity="error">{error}</Alert>}
    </>
  )
}

export default JobPostingForm
