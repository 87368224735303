import {ArrowDropDown} from "@mui/icons-material"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import {Alert, Box, Button, MenuItem, Stack, TextField} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers"
import {Address, PastEmployerInfo} from "api/sdk"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import dayjs from "dayjs"
import {FieldArray, FormikProvider, useFormik} from "formik"
import React, {FC, useEffect} from "react"
import {stateCodeOptions} from "utils/constants"
import {handleArrayValidationErrors} from "utils/helpers/helpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"
import { RadioInput, makeFormikRadioInputProps } from "components/RadioInput"
import { LoadingButton } from "@mui/lab"

const validationSchema = yup.object().shape({
  employmentHistory: yup.array().of(
    yup.object().shape({
      name: yup.string().nullable().required("Required"),
      phone: yup.string().nullable().required("Required"),
      address: yup.object().shape({
        line1: yup.string().nullable().required("Required"),
        line2: yup.string(),
        city: yup.string().nullable().required("Required"),
        state: yup.string().nullable().required("Required"),
        postalCode: yup.string().nullable().required("Required")
      }),
      positionHeld: yup.string().nullable().required("Required"),
      startDate: yup.date().typeError("Please input date as MM/DD/YYYY").nullable().required("Required"),
      endDate: yup.date().nullable().typeError("Please input date as MM/DD/YYYY").required("Required"),
      salary: yup.number().nullable().typeError("Please enter a number").required("Required"),
      subjectToFMCSR: yup.string().nullable().required("Required"),
      reasonForLeaving: yup.string().nullable().required("Required"),
      designatedRepresentative: yup.string().nullable().required("Required")
    })
  ),
  prevJob49CFRPart40:yup.string().nullable().required("Required")
})

type PastEmployerInfoErrorKey =
  | keyof Address
  | "name"
  | "phone"
  | "positionHeld"
  | "startDate"
  | "endDate"
  | "salary"
  | "subjectToFMCSR"
  | "reasonForLeaving"
  | "gapExplain"
  | "designatedRepresentative"

const TermsOfService: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep, refreshApplication, requiresLicenseInfo} = props

  const [error,setError] = React.useState<string | null>(null)
  const [arrayErrors, setArrayErrors] = React.useState<
    ReturnType<typeof handleArrayValidationErrors<PastEmployerInfoErrorKey>>
  >(application.employmentHistory.map((x) => ({})))

  const blank: PastEmployerInfo = {
    name: null,
    phone: null,
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      postalCode: ""
    },
    positionHeld: null,
    startDate: null,
    endDate: null,
    salary: null,
    subjectToFMCSR: "no",
    reasonForLeaving: null,
    gapExplain: null,
    designatedRepresentative: null
  }

  const checkIfTenYearsTotalExperienceForCDL = (employmentHistory:PastEmployerInfo[]) => {
    const totalYears = employmentHistory.reduce((total, employer) => {
      const startDate = dayjs(employer.startDate)
      const endDate = dayjs(employer.endDate)
      const years = endDate.diff(startDate, "year")
      return total + years
    }, 0)
    return totalYears >= 10
  }

  const formik = useFormik({
    initialValues: {
      employmentHistory:
        application.employmentHistory.length === 0
          ? [blank]
          : application.employmentHistory
          ,
          prevJob49CFRPart40: application.prevJob49CFRPart40===null || application.prevJob49CFRPart40===undefined  ? "" : 
          application.prevJob49CFRPart40 ? "yes" : "no"
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      if(requiresLicenseInfo){
        if(!checkIfTenYearsTotalExperienceForCDL(values.employmentHistory)){
          setError("All CDL Applications require the last 10 years of employment history per FMCSA regulations")
          throw new Error("All CDL Applications require the last 10 years of employment history per FMCSA regulations")
        }
      }
      if(values.employmentHistory.length === 0){
        setError("Please add at least one employer")
        throw new Error("Please add at least one employer")
      }

      saveProgress({
        ...application,
        employmentHistory: values.employmentHistory,
        prevJob49CFRPart40: values.prevJob49CFRPart40 === "yes"
      })
      nextStep()
    }
  })

  useEffect(() => {

    console.log("test",application.prevJob49CFRPart40)
    if (formik.submitCount === 0) return

    const errors = handleArrayValidationErrors<PastEmployerInfoErrorKey>(
      formik.errors.employmentHistory
    )
    setArrayErrors(errors)
  }, [formik.errors, formik.submitCount])

  return (
    <>
      <FormikProvider value={formik}>
        <FieldArray name="employmentHistory" >
          {({insert, remove, push, replace}) => (
            <>
              {formik.values.employmentHistory.map((employer, index) => (
                <FormSection
                  title={`Employment History ${index + 1}`}
                  subtitle={requiresLicenseInfo? "All CDL Applications require the last 10 years of employment history per FMCSA regulations" : null}
                  key={index}
                  disableTopPadding={index === 0}
                  formAction={
                    <Button
                    variant="contained"
                    sx={{alignSelf: "flex-start"}}
                    onClick={() => remove(index)}
                    startIcon={<DeleteIcon />}
                  >
                    Remove Employer
                  </Button>
                  }

                >
                  <TextField
                    label="Employer Name"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `employmentHistory.${index}.name`,
                        e.target.value
                      )
                    }}
                    value={formik.values.employmentHistory[index].name}
                    helperText={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).name
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).name !==
                          undefined
                        : false
                    }
                  />
                  <TextField
                    label="Employer Phone"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `employmentHistory.${index}.phone`,
                        e.target.value
                      )
                    }}
                    value={formik.values.employmentHistory[index].phone}
                    helperText={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).phone
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).phone !==
                          undefined
                        : false
                    }
                  />
                  <TextField
                    label="Street Address"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `employmentHistory.${index}.address.line1`,
                        e.target.value
                      )
                    }}
                    value={
                      formik.values.employmentHistory[index].address?.line1 ??
                      ""
                    }
                    helperText={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).address
                            ?.line1
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).address
                            ?.line1 !== undefined
                        : false
                    }
                  />
                  <TextField
                    label="Address Line 2"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `employmentHistory.${index}.address.line2`,
                        e.target.value
                      )
                    }}
                    value={
                      formik.values.employmentHistory[index].address?.line2 ??
                      ""
                    }
                    helperText={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).address
                            ?.line2
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).address
                            ?.line2 !== undefined
                        : false
                    }
                  />
                  <Stack direction="row" spacing={2}>
                    <TextField
                      fullWidth
                      label="City"
                      onChange={(e) => {
                        formik.setFieldValue(
                          `employmentHistory.${index}.address.city`,
                          e.target.value
                        )
                      }}
                      value={
                        formik.values.employmentHistory[index].address?.city ??
                        ""
                      }
                      helperText={
                        (arrayErrors[index] as PastEmployerInfo) !== undefined
                          ? (arrayErrors[index] as PastEmployerInfo).address
                              ?.city
                          : ""
                      }
                      error={
                        (arrayErrors[index] as PastEmployerInfo) !== undefined
                          ? (arrayErrors[index] as PastEmployerInfo).address
                              ?.city !== undefined
                          : false
                      }
                    />
                    
                    <TextField
                      fullWidth
                      select
                      label="State"
                      onChange={(e) => {
                        formik.setFieldValue(
                          `employmentHistory.${index}.address.state`,
                          e.target.value
                        )
                      }}
                      value={
                        formik.values.employmentHistory[index].address?.state ??
                        ""
                      }
                      helperText={
                        (arrayErrors[index] as PastEmployerInfo) !== undefined
                          ? (arrayErrors[index] as PastEmployerInfo).address
                              ?.state
                          : ""
                      }
                      error={
                        (arrayErrors[index] as PastEmployerInfo) !== undefined
                          ? (arrayErrors[index] as PastEmployerInfo).address
                              ?.state !== undefined
                          : false
                      }
                    >
                      {stateCodeOptions.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      fullWidth
                      label="Postal Code"
                      onChange={(e) => {
                        formik.setFieldValue(
                          `employmentHistory.${index}.address.postalCode`,
                          e.target.value
                        )
                      }}
                      value={
                        formik.values.employmentHistory[index].address
                          ?.postalCode ?? ""
                      }
                      helperText={
                        (arrayErrors[index] as PastEmployerInfo) !== undefined
                          ? (arrayErrors[index] as PastEmployerInfo).address
                              ?.postalCode
                          : ""
                      }
                      error={
                        (arrayErrors[index] as PastEmployerInfo) !== undefined
                          ? (arrayErrors[index] as PastEmployerInfo).address
                              ?.postalCode !== undefined
                          : false
                      }
                    />
                  </Stack>
                  <TextField
                    label="Country"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `employmentHistory.${index}.address.country`,
                        e.target.value
                      )
                    }}
                    value={
                      formik.values.employmentHistory[index].address?.country ??
                      ""
                    }
                    helperText={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).address
                            ?.country
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).address
                            ?.country !== undefined
                        : false
                    }
                  />
                  <TextField
                    label="Position Held"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `employmentHistory.${index}.positionHeld`,
                        e.target.value
                      )
                    }}
                    value={formik.values.employmentHistory[index].positionHeld}
                    helperText={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).positionHeld
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo)
                            .positionHeld !== undefined
                        : false
                    }
                  />
                  <DatePicker
                    label="Start Date"
                    onChange={(e) => {
                      const date = dayjs(
                        e === null || e === undefined ? dayjs() : e
                      ).format("YYYY-MM-DD")
                      formik.setFieldValue(
                        `employmentHistory.${index}.startDate`,
                        date
                      )
                    }}
                    value={formik.values.employmentHistory[index].startDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          (arrayErrors[index] as PastEmployerInfo) !== undefined
                            ? (arrayErrors[index] as PastEmployerInfo).startDate
                            : ""
                        }
                        error={
                          (arrayErrors[index] as PastEmployerInfo) !== undefined
                            ? (arrayErrors[index] as PastEmployerInfo)
                                .startDate !== undefined
                            : false
                        }
                      />
                    )}
                    minDate={dayjs().subtract(120, "year")}
                    maxDate={dayjs()}
                  />
                  <DatePicker
                    label="End Date"
                    onChange={(e) => {
                      const date = dayjs(
                        e === null || e === undefined ? dayjs() : e
                      ).format("YYYY-MM-DD")
                      formik.setFieldValue(
                        `employmentHistory.${index}.endDate`,
                        date
                      )
                    }}
                    value={formik.values.employmentHistory[index].endDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          (arrayErrors[index] as PastEmployerInfo) !== undefined
                            ? (arrayErrors[index] as PastEmployerInfo).endDate
                            : ""
                        }
                        error={
                          (arrayErrors[index] as PastEmployerInfo) !== undefined
                            ? (arrayErrors[index] as PastEmployerInfo)
                                .endDate !== undefined
                            : false
                        }
                      />
                    )}
                    minDate={dayjs().subtract(120, "year")}
                    maxDate={dayjs()}
                  />
                  <TextField
                    label="Salary"
                    onChange={(e) => {
                      const value = Number(e.target.value)
                      formik.setFieldValue(
                        `employmentHistory.${index}.salary`,
                        isNaN(value) ? e.target.value : value
                      )
                    }}
                    value={formik.values.employmentHistory[index].salary}
                    helperText={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).salary
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).salary !==
                          undefined
                        : false
                    }
                  />
                          <RadioInput
          label="Subject to FMCSR"
          options={[
            {label: "Yes", value: "yes"},
            {label: "No", value: "no"}
          ]}
          name="Subject to FMCSR"
          value={formik.values.employmentHistory[index].subjectToFMCSR??"no"}
          helperText={(arrayErrors[index] as PastEmployerInfo) !== undefined? (arrayErrors[index] as PastEmployerInfo).subjectToFMCSR??"":""}
          error={
            (arrayErrors[index] as PastEmployerInfo) !== undefined
              ? (arrayErrors[index] as PastEmployerInfo).subjectToFMCSR !==
                undefined
              : false
          }
          onChange={(e) => {
            formik.setFieldValue(
              `employmentHistory.${index}.subjectToFMCSR`,
              e.target.value
            )
          }}
        />
                  <TextField
                    label="Reason for Leaving"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `employmentHistory.${index}.reasonForLeaving`,
                        e.target.value
                      )
                    }}
                    value={
                      formik.values.employmentHistory[index].reasonForLeaving
                    }
                    helperText={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo)
                            .reasonForLeaving
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo)
                            .reasonForLeaving !== undefined
                        : false
                    }
                  />
                  <TextField
                    label="Gap Explain"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `employmentHistory.${index}.gapExplain`,
                        e.target.value
                      )
                    }}
                    value={formik.values.employmentHistory[index].gapExplain}
                    helperText={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo).gapExplain
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo)
                            .gapExplain !== undefined
                        : false
                    }
                  />
                  <TextField
                    label="Designated Representative (supervisor or manager etc)"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `employmentHistory.${index}.designatedRepresentative`,
                        e.target.value
                      )
                    }}
                    value={
                      formik.values.employmentHistory[index]
                        .designatedRepresentative
                    }
                    helperText={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo)
                            .designatedRepresentative
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PastEmployerInfo) !== undefined
                        ? (arrayErrors[index] as PastEmployerInfo)
                            .designatedRepresentative !== undefined
                        : false
                    }
                  />

                </FormSection>
              ))}
              <Box >
              <Button
                variant="contained"
                sx={{mt: 2,mb:2}}
                onClick={() => {
                  push(blank)
                }}
                startIcon={<AddIcon />}
              >
                Add Employer or position
              </Button>
              </Box>
            </>
          )}
        </FieldArray>


        <RadioInput
          label="Were any of the previous job positions designated as a safety sensitive function in any DOT regulated mode, subject to alcohol and
controlled substances testing requirements as required by 49 CFR Part 40?"
          options={[
            {label: "Yes", value: "yes"},
            {label: "No", value: "no"}
          ]}
          {...makeFormikRadioInputProps(formik, "prevJob49CFRPart40")}
        />

        
        <LoadingButton
            endIcon={<ArrowDropDown />}
            variant="contained"
            loading={formik.isSubmitting}
            disabled={!formik.dirty}
            onClick={()=>{formik.submitForm().catch((e)=>{
              formik.setSubmitting(false)
            })}}
            sx={{mt: 4}}
          >
            Save and Continue
          </LoadingButton>

          {error && (
        <Alert severity="error" sx={{mt: 2}}>
          {error}
        </Alert>
      )}
      
      </FormikProvider>
    </>
  )
}
export default TermsOfService
