import {ArrowDropDown} from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {Typography} from "@mui/material"
import {AuthContext} from "App"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import GStyleFileUpload, {UploadType} from "components/GStyleFileUpload"
import {useFormik} from "formik"
import React, {FC, useContext, useState} from "react"
import {uploadBlobFile} from "utils/helpers/helpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"

const validationSchema = yup.object().shape({})

const Documents: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep, requiresLicenseInfo} = props
  const {session} = useContext(AuthContext)

  const [files, setFiles] = useState<File[]>([])


  const addFileToFileList = (file: File | null) => {
    if (file !== null) {
      setFiles([...files, file])
    }
  }

  const uploadFile = async (file: File) => {
    const {uploadUrl, futureCallToken} = await session
      .uploadFileForRequest()
      .catch(() => {
        throw new Error("Error getting upload URL")
      })

    await uploadBlobFile(uploadUrl, file)
    return futureCallToken
  }

  const formik = useFormik({
    initialValues: {
      additionalDocuments: application.additionalDocuments
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      const futuralCallTokens: Promise<string>[] = []
      files.forEach((file) => {
        const futureCallToken = uploadFile(file)
        futuralCallTokens.push(futureCallToken)
      })

      Promise.all(futuralCallTokens).then((futuralCallTokens) => {
        values.additionalDocuments.push(...futuralCallTokens)
        saveProgress({
          ...application,
          additionalDocuments: values.additionalDocuments
        })
        nextStep()
      })
      nextStep()
    }
  })

  return (
    <>
      {application.additionalDocuments.length !== 0 && (
        <FormSection disableTopPadding title=" Uploaded Documents">
          {application.additionalDocuments.map((document, index) => (
            <Typography key="index" variant="h6" sx={{mt: 4}}>
              <a href={document}>Document {index + 1}</a>
            </Typography>
          ))}
        </FormSection>
      )}
      <FormSection
        title="Add Documents"
        disableTopPadding={application.additionalDocuments.length === 0}
      >
        { requiresLicenseInfo && (
          <>
          <Typography variant="h6" sx={{mt: 4}}>
            Copy of Medical Card (Required for CDL Driver)
          </Typography>
          <GStyleFileUpload
            file={null}
            setFile={addFileToFileList}
            uploadType={UploadType.Document}
          ></GStyleFileUpload>
          </>
        )}
        



{/* <Typography 
          variant="h6"
          sx={{mt: 4}}
        >
          Filled out MVR Driver Authorization and Release
        </Typography>
          <GStyleFileUpload
            file={null}
            setFile={addFileToFileList}
            uploadType={UploadType.Document}
          ></GStyleFileUpload> */}


<Typography
          variant="h6"
          sx={{mt: 4}}
        >
          Copy of Resume (optional)
        </Typography>
          <GStyleFileUpload
            file={null}
            setFile={addFileToFileList}
            uploadType={UploadType.Document}
          ></GStyleFileUpload>
<Typography 
          variant="h6"
          sx={{mt: 4}}
        >
          Other Document (optional)
        </Typography>
          <GStyleFileUpload
            file={null}
            setFile={addFileToFileList}
            uploadType={UploadType.Document}
          ></GStyleFileUpload>

{files.length > 0 &&(
  <>
<Typography 
          variant="h6"
          sx={{mt: 4}}
        >
          Uploaded Documents
        </Typography>
        {files.map((file, index) => (
          <GStyleFileUpload
            key={index}
            file={file}
            setFile={addFileToFileList}
            uploadType={UploadType.Document}
          ></GStyleFileUpload>
        ))}
          </>
        )}


      </FormSection>
      <LoadingButton
          endIcon={<ArrowDropDown />}
          variant="contained"
          loading={formik.isSubmitting}
          onClick={()=>{formik.submitForm()}}
          sx={{mt: 4}}
        >
          Save and Continue
        </LoadingButton>
    </>
  )
}

export default Documents
