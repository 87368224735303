import {Box, Container} from "@mui/material"
import React, {FC, ReactNode} from "react"

export const MainLayout: FC<{children: ReactNode}> = ({children}) => {
  return (
    <Box
      bgcolor="background.default"
      width="100%"
      pt={5}
      pb={7}
      minHeight="100vh"
    >
      <Container maxWidth="md">{children}</Container>
    </Box>
  )
}
