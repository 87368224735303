import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Stack,
  Typography
} from "@mui/material"
import {Application, JobPosting} from "api/sdk"
import {AuthContext} from "App"
import React, {FC, useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import { getEmptyApplication } from "utils/helpers/helpers"

interface PlaceholderJob {
  _id: string
  title: string
  description: string
  salary: number
  status: string
}

const UserJobPostings: FC = () => {
  const {session, currentUser, logout} = useContext(AuthContext)
  const navigate = useNavigate()
  const [placeHolderJobs, setPlaceHolderJobs] = useState<PlaceholderJob[]>([
    {
      _id: "1",
      title: "Software Engineer",
      description: "This is a placeholder job description",
      salary: 100000,
      status: "in progress"
    },
    {
      _id: "2",
      title: "Software Engineer",
      description: "This is a placeholder job description",
      salary: 100000,
      status: "open"
    },
    {
      _id: "3",
      title: "Software Engineer",
      description: "This is a placeholder job description",
      salary: 100000,
      status: "submitted"
    }
  ])

  const [jobPostingList, setJobPostingList] = useState<JobPosting[]>([])
  const [applications, setApplications] = useState<Application[]>([])

  useEffect(() => {
    session.jobPosting
      .query({
        condition: {published: {Equal: true}}
      })
      .then((jobs) => {
        setJobPostingList(jobs)
        const jobPostingIds = jobs.map((job) => job._id)
        console.log("testing job description", jobs[1])
        session.application
          .query({condition: {owner: {Equal: currentUser._id}}})
          .then((applications) => {
            const applicationsCurrentJobPostings = applications.filter(
              (application) => jobPostingIds.includes(application.jobPosting)
            )
            setApplications(applicationsCurrentJobPostings)
          })
      })
  }, [currentUser])

  const getApplicationWIthId = (jobPostingId: string) => {
    const application = applications.find(
      (application) => application.jobPosting === jobPostingId
    )
    return application
  }
  const getApplicationStatus = (jobPostingId: string) => {
    const application = getApplicationWIthId(jobPostingId)
    if (application) {
      if (application.signature !== null) {
        return "submitted"
      }
      return "in progress"
    }

    return null
  }

  return (
    <>
      <Stack direction="row" spacing={2} sx={{mb: 2}}>
        <Button onClick={logout}>Log Out</Button>
      </Stack>

      <Typography variant="h1" mb={4}>
        GStyle Job Postings
      </Typography>
      <Stack spacing={2} sx={{mb: 2}}>
        {jobPostingList.map((job, index) => (
          <Card key={index}>
            <CardContent>
              <Typography sx={{margin: 1}} variant="h2">
                {job.title}
              </Typography>
              <Typography sx={{margin: 1}} variant="h3">
                {job.description.split("\n").map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </Typography>
              <Typography sx={{margin: 1}} variant="h3">
                Salary: {job.salaryRangeBegin} - {job.salaryRangeEnd}
              </Typography>
              {getApplicationStatus(job._id) === "in progress" && (
                <Alert severity="info">
                  <AlertTitle>In progress</AlertTitle>
                  <Button
                    sx={{mt: 1, minWidth: 120, alignSelf: "flex-end"}}
                    variant="outlined"
                    onClick={() => {
                      navigate(
                        `/application/${
                          getApplicationWIthId(job._id)?._id ?? ""
                        }/${String(job.requiresLicenseInfo)}/`
                      )
                    }}
                  >
                    Continue Application
                  </Button>
                </Alert>
              )}
              {!getApplicationStatus(job._id) && (
                <Button
                  sx={{mt: 1, minWidth: 120, alignSelf: "flex-end"}}
                  variant="outlined"
                  onClick={() => {
                    session.application
                        .insert(
                          getEmptyApplication(currentUser, job._id ?? "")
                        )
                        .then((application) => {
                    navigate(
                      `/application/${application._id}/${String(
                        job.requiresLicenseInfo
                      )}/`)
                      })
                  }}
                >
                  Apply to Job
                </Button>
              )}
              {getApplicationStatus(job._id) === "submitted" && (
                <Alert sx={{margin: 1}} severity="success">
                  <AlertTitle>Submitted</AlertTitle>
                </Alert>
              )}
            </CardContent>
          </Card>
        ))}
      </Stack>
    </>
  )
}
export default UserJobPostings
