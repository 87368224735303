import {faker} from "@faker-js/faker"
import {JobPosting} from "api/sdk"

export function generateJobPostings(total: number): JobPosting[] {
  return Array.from({length: total}, () => ({
    _id: faker.datatype.uuid(),
    title: faker.random.word(),
    positionsAvailable: faker.datatype.number(),
    published: faker.datatype.boolean(),
    description: faker.lorem.paragraph(),
    salaryRangeBegin: faker.datatype.number(),
    salaryRangeEnd: faker.datatype.number(),
    requiresLicenseInfo: faker.datatype.boolean()
  }))
}
