import {makeObjectModification} from "@lightningkite/lightning-server-simplified"
import {
  makeFormikCheckboxProps,
  makeFormikTextFieldProps
} from "@lightningkite/mui-lightning-components"
import {LoadingButton} from "@mui/lab"
import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import {User} from "api/sdk"
import {AuthContext} from "App"
import {useFormik} from "formik"
import React, {FC, useContext, useEffect, useState} from "react"
import * as yup from "yup"

// Form validation schema. See: https://www.npmjs.com/package/yup#object
const validationSchema = yup.object().shape({
  email: yup.string().required("Email is required")
})

export interface UserFormProps {
  user: User
  refreshUser: () => Promise<void>
}

export const UserForm: FC<UserFormProps> = (props) => {
  const {user, refreshUser} = props

  const {session} = useContext(AuthContext)

  const [error, setError] = useState("")

  // Formik is a library for managing form state. See: https://formik.org/docs/overview
  const formik = useFormik({
    initialValues: {
      email: user.email,
      isSuperUser: user.isSuperUser
    },
    validationSchema,
    // When the form is submitted, this function is called if the form values are valid
    onSubmit: async (values) => {
      setError("")

      // Automatically builds the Lightning Server modification given the old object and the new values
      const modification = makeObjectModification(user, values)

      // Handle the case where nothing changed (this shouldn't happen, but we gotta make TypeScript happy)
      if (!modification) {
        return
      }

      try {
        await session.user.modify(user._id, modification)
        await refreshUser()
      } catch {
        setError("Error updating user")
      }
    }
  })

  // Reset the form when the user changes or refreshes
  useEffect(() => {
    formik.resetForm({values: user})
  }, [user])

  return (
    <Stack gap={3}>
      <TextField label="Email" {...makeFormikTextFieldProps(formik, "email")} />

      <FormControlLabel
        control={
          <Checkbox {...makeFormikCheckboxProps(formik, "isSuperUser")} />
        }
        label={
          <>
            <Typography>Super User</Typography>

            <FormHelperText>
              Super users have full access to the admin portal and can manage
              all users and applications
            </FormHelperText>
          </>
        }
      />

      {error && <Alert severity="error">{error}</Alert>}

      <LoadingButton
        onClick={() => {
          formik.submitForm()
        }}
        variant="contained"
        color="primary"
        loading={formik.isSubmitting}
        style={{alignSelf: "end"}}
        disabled={!formik.dirty}
      >
        {formik.dirty ? "Save Changes" : "Saved"}
      </LoadingButton>
    </Stack>
  )
}
