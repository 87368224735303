import {Application} from "api/sdk"
import Agreements from "pages/UserApplicationPage/Steps/Agreements"
import CriminalRecord from "pages/UserApplicationPage/Steps/CriminalRecord"
import Documents from "pages/UserApplicationPage/Steps/Documents"
import DrivingExperience from "pages/UserApplicationPage/Steps/DrivingExperience"
import EmploymentHistory from "pages/UserApplicationPage/Steps/EmploymentHistory"
import Licenses from "pages/UserApplicationPage/Steps/Licenses"
import NotImplemented from "pages/UserApplicationPage/Steps/NotImplemented"
import PersonalInformation from "pages/UserApplicationPage/Steps/PersonalInformation"
import Residency from "pages/UserApplicationPage/Steps/Residency"
import Signature from "pages/UserApplicationPage/Steps/Signature"
import {FormStepContentProps} from "pages/UserApplicationPage/UserApplicationPage"
import {FC} from "react"

export enum StepIndex {
  PERSONAL_INFORMATION,
  RESIDENCY,
  LICENSES,
  DRIVING_EXPERIENCE,
  ACCIDENT_TRAFFIC_RECORD,
  EMPLOYMENT_HISTORY,
  AGREEMENTS,
  DOCUMENTS,
  SIGNATURE
}

export const StepIndexNames: Record<StepIndex, string> = {
  [StepIndex.PERSONAL_INFORMATION]: "Personal Information",
  [StepIndex.RESIDENCY]: "Residency",
  [StepIndex.LICENSES]: "License Information",
  [StepIndex.DRIVING_EXPERIENCE]: "Driving Experience",
  [StepIndex.ACCIDENT_TRAFFIC_RECORD]: "Accident/Traffic Record",
  [StepIndex.EMPLOYMENT_HISTORY]: "Employment History",
  [StepIndex.AGREEMENTS]: "Agreements",
  [StepIndex.DOCUMENTS]: "Documents",
  [StepIndex.SIGNATURE]: "Signature"
}

export interface FormStep {
  title: string
  subtitle?: string
  content: FC<FormStepContentProps>
}

export const steps: Record<StepIndex, FormStep> = {
  [StepIndex.PERSONAL_INFORMATION]: {
    title: "Personal Information",
    subtitle: "Enter your personal information.",
    content: PersonalInformation
  },
  [StepIndex.RESIDENCY]: {
    title: "Residency Information",
    subtitle: "Enter your residency information.",
    content: Residency
  },
  [StepIndex.LICENSES]: {
    title: "License Information",
    content: Licenses
  },
  [StepIndex.DRIVING_EXPERIENCE]: {
    title: "Driving Experience",
    content: DrivingExperience
  },
  [StepIndex.ACCIDENT_TRAFFIC_RECORD]: {
    title: "Criminal Record",
    content: CriminalRecord
  },
  [StepIndex.EMPLOYMENT_HISTORY]: {
    title: "Employment History",
    content: EmploymentHistory
  },
  [StepIndex.AGREEMENTS]: {
    title: "Agreements",
    content: Agreements
  },
  [StepIndex.DOCUMENTS]: {
    title: "Documents",
    content: Documents
  },
  [StepIndex.SIGNATURE]: {
    title: "Signature",
    content: Signature
  }
}

export const getLastCompletedStep = (
  application: Application
): StepIndex | null => {
  const stepCompletion: Record<StepIndex, boolean> = {
    [StepIndex.PERSONAL_INFORMATION]:
      !!application.applicant &&
      Object.values(application.applicant).every((value) => value !== null),
    [StepIndex.RESIDENCY]:
      !!application.priorResidences && application.priorResidences.length > 0,
    [StepIndex.LICENSES]:
      !!application.licenses && application.licenses.length > 0,
    [StepIndex.DRIVING_EXPERIENCE]: !!application.experienceInfo,
    [StepIndex.ACCIDENT_TRAFFIC_RECORD]: !!application.criminalInfo,
    [StepIndex.EMPLOYMENT_HISTORY]:
      !!application.employmentHistory &&
      application.employmentHistory.length > 0,
    [StepIndex.AGREEMENTS]:
      !!application.drivingHistoryDisclosure &&
      !!application.backgroundCheckDisclosure,
    [StepIndex.DOCUMENTS]:
    !!application.drivingHistoryDisclosure &&
    !!application.backgroundCheckDisclosure,
    [StepIndex.SIGNATURE]:
      !!application.signature &&
      Object.values(application.signature).every((value) => value !== null)
  }

  // Null if no steps are completed
  if (Object.values(stepCompletion).every((value) => !value)) {
    return null
  }

  // Find the first step where the next step is not completed
  return Number(
    Object.keys(stepCompletion).find(
      (index) => !stepCompletion[(+index + 1) as StepIndex]
    )
  )
}

export const getCurrentStepLabel = (application: Application): string => {
  const lastCompletedStep = getLastCompletedStep(application) ?? -1
  if (lastCompletedStep === StepIndex.SIGNATURE) return "Completed"
  return `${StepIndexNames[(lastCompletedStep + 1) as StepIndex]}`
}

export const isFormComplete = (application: Application): boolean => {
  let complete = true
  const keysToSkip = ["additionalDocuments", "signature","ipAddress","readyForReview","pdfs","prevJob49CFRPart40","review"]
  Object.keys(application).map((key) => {
    if (application[key as keyof Application] === null && !keysToSkip.includes(key)){
    complete = false
    }
  if (Array.isArray(application[key as keyof Application])){
    if ((application[key as keyof Application] as []).length === 0 && !keysToSkip.includes(key))
    {
      complete = false
    }
  }
  return complete
})

  return complete
}
