import {ArrowDropDown} from "@mui/icons-material"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import {Button, MenuItem, Stack, TextField} from "@mui/material"
import {Address, PriorResidence} from "api/sdk"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import FormSection from "components/FormSection"
import {FieldArray, FormikProvider, useFormik} from "formik"
import React, {FC, useEffect} from "react"
import {stateCodeOptions} from "utils/constants"
import {handleArrayValidationErrors} from "utils/helpers/helpers"
import * as yup from "yup"
import {FormStepContentProps} from "../UserApplicationPage"
import {LoadingButton} from "@mui/lab"

const validationSchema = yup.object({
  priorResidences: yup.array().of(
    yup.object().shape({
      address: yup.object().shape({
        line1: yup.string().required("Required"),
        line2: yup.string(),
        city: yup.string().required("Required"),
        state: yup.string().required("Required"),
        country: yup.string().required("Required"),
        postalCode: yup.string().required("Required")
      }),
      startYear: yup
        .number()
        .min(1900)
        .max(2100)
        .required("Please enter correct date range")
        .nullable(),
      endYear: yup
        .number()
        .min(1900)
        .max(2100)
        .required("Please enter correct date range")
        .nullable()
    })
  )
})

type AddressErrorKey = keyof Address | "startYear" | "endYear"

// type AddressError = Partial<Record<(keyof Address) | keyof Omit< PriorResidence, 'address'>, string>>

const Residency: FC<FormStepContentProps> = (props) => {
  const {application, saveProgress, nextStep} = props

  const [arrayErrors, setArrayErrors] = React.useState<
    ReturnType<typeof handleArrayValidationErrors<AddressErrorKey>>
  >(application.priorResidences.map((x) => ({})))

  const blank: PriorResidence = {
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      postalCode: ""
    },
    startYear: null,
    endYear: null
  }

  const formik = useFormik({
    initialValues: {
      priorResidences:
        application.priorResidences.length === 0
          ? []
          : application.priorResidences
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      saveProgress({
        ...application,
        priorResidences: values.priorResidences
      })
      nextStep()
    }
  })

  useEffect(() => {
    if (formik.submitCount === 0) return

    const errors = handleArrayValidationErrors<AddressErrorKey>(
      formik.errors.priorResidences
    )
    setArrayErrors(errors)
  }, [formik.errors, formik.submitCount])

  return (
    <>
      <FormikProvider value={formik}>
        <FieldArray name="priorResidences">
          {({insert, remove, push, replace}) => (
            <div>
              {formik.values.priorResidences.map((priorResidence, index) => (
                <FormSection key={index} title={`Prior Residency ${index + 1}`} formAction={
                  <Button
                    variant="contained"
                    sx={{alignSelf: "flex-start"}}
                    onClick={() => remove(index)}
                    startIcon={<DeleteIcon />}
                  >
                    Remove Prior Address
                  </Button>
                }>
                  <TextField
                    label="Street Address"
                    value={priorResidence.address?.line1 ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `priorResidences.${index}.address.line1`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).address?.line1
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).address
                            ?.line1 !== undefined
                        : false
                    }
                  />
                  <TextField
                    label="line2"
                    value={priorResidence.address?.line2 ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `priorResidences.${index}.address.line2`,
                        e.target.value
                      )
                    }}
                  />
                  <TextField
                    label="City"
                    value={priorResidence.address?.city ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `priorResidences.${index}.address.city`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).address?.city
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).address
                            ?.city !== undefined
                        : false
                    }
                  />
                  <TextField
                    fullWidth
                    select
                    value={priorResidence.address?.state ?? ""}
                    label="State"
                    onChange={(e) => {
                      formik.setFieldValue(
                        `priorResidences.${index}.address.state`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).address?.state
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).address
                            ?.state !== undefined
                        : false
                    }
                  >
                    {stateCodeOptions.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    label="Postal Code"
                    value={priorResidence.address?.postalCode ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `priorResidences.${index}.address.postalCode`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).address
                            ?.postalCode
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).address
                            ?.postalCode !== undefined
                        : false
                    }
                  />

                  <TextField
                    label="Country"
                    value={priorResidence.address?.country ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `priorResidences.${index}.address.country`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).address
                            ?.country
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).address
                            ?.country !== undefined
                        : false
                    }
                  />

                  <TextField
                    label="Start Year"
                    value={priorResidence.startYear ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `priorResidences.${index}.startYear`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).startYear
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).startYear !==
                          undefined
                        : false
                    }
                  />

                  <TextField
                    label="End Year"
                    value={priorResidence.endYear ?? ""}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `priorResidences.${index}.endYear`,
                        e.target.value
                      )
                    }}
                    helperText={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).endYear
                        : ""
                    }
                    error={
                      (arrayErrors[index] as PriorResidence) !== undefined
                        ? (arrayErrors[index] as PriorResidence).endYear !==
                          undefined
                        : false
                    }
                  />
                </FormSection>
              ))}
              <Button
                variant="contained"
                sx={{mt: 2}}
                onClick={() => {
                  push(blank)
                }}
                startIcon={<AddIcon />}
              >
                Add Prior Address
              </Button>
            </div>
          )}
        </FieldArray>
      </FormikProvider>
      <LoadingButton
        endIcon={<ArrowDropDown />}
        variant="contained"
        loading={formik.isSubmitting}
        onClick={()=>{
          formik.submitForm()
        }}
        sx={{mt: 4}}
        disabled={!formik.dirty}
      >
        Save and Continue
      </LoadingButton>
    </>
  )
}

export default Residency
