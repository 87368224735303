import {Alert, List, ListItemButton, Typography} from "@mui/material"
import {Application, User} from "api/sdk"
import {AuthContext} from "App"
import Loading from "components/Loading"
import React, {FC, useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"

export interface UserApplicationProps {
  user: User
}

export const UserApplication: FC<UserApplicationProps> = ({user}) => {
  const {session} = useContext(AuthContext)
  const navigate = useNavigate()

  const [applications, setApplications] = useState<
    Application[] | null | undefined
  >()

  useEffect(() => {
    session.application
      .query({condition: {owner: {Equal: user._id}}})
      .then(setApplications)
      .catch(() => setApplications(null))
  }, [user._id, session])

  if (applications === undefined) return <Loading />
  if (applications === null)
    return <Alert severity="error">Error loading applications</Alert>

  return (
    <>
      <Typography variant="h2" sx={{mb: 2}}>
        Applications
      </Typography>

      {applications.length === 0 ? (
        <Typography variant="body1" fontStyle="italic">
          This user has not started any applications
        </Typography>
      ) : (
        <List sx={{pb: 0}}>
          {applications.map((application) => (
            <ListItemButton
              key={application._id}
              onClick={() => navigate(`/users/${user._id}/${application._id}`)}
            >
              {/* <ListItemText
                primary={
                  application.applicant?.firstName  ?? (
                    <i>Business name not provided</i>
                  )
                }
                secondary={"Current Step: " + getCurrentStepLabel(application)}
              /> */}
            </ListItemButton>
          ))}
        </List>
      )}
    </>
  )
}
