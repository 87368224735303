import {RestDataTable} from "@lightningkite/mui-lightning-components"
import {Container} from "@mui/material"
import {AuthContext} from "App"
import PageHeader from "components/PageHeader"
import React, {FC, useContext} from "react"
import {useNavigate} from "react-router-dom"

export interface ApplicationIndexProps {
  onlyNeedsReview?: boolean
}

const ApplicationIndex: FC<ApplicationIndexProps> = ({
  onlyNeedsReview = false
}) => {
  const {session} = useContext(AuthContext)
  const navigate = useNavigate()

  return (
    <Container maxWidth="md">
      <PageHeader
        title={onlyNeedsReview ? "Needs Review" : "All Applications"}
      />

      <RestDataTable
        onRowClick={(application) =>
          navigate(
            onlyNeedsReview
              ? `/needs-review/${application._id}`
              : `/applications/${application._id}`
          )
        }
        restEndpoint={session.application}
        additionalQueryConditions={
          onlyNeedsReview ? [{readyForReview: {Equal: true}}] : []
        }
        columns={[
          {
            field: "applicant",
            headerName: "Applicant",
            flex: 1,
            valueGetter: ({row}) =>
              `${row.applicant?.firstName ?? ""} ${
                row.applicant?.lastName ?? ""
              }`,
            renderCell: ({value}) => value ?? <i>Not provided</i>,
            sortable: false
          }
        ]}
      />
    </Container>
  )
}

export default ApplicationIndex
