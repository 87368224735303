import {makeObjectModification} from "@lightningkite/lightning-server-simplified"
import {ExpandMore} from "@mui/icons-material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  Typography
} from "@mui/material"
import {Application, JobPosting, SignupFormStatus, User} from "api/sdk"
import {AuthContext} from "App"
import {AutoLoadingButton} from "components/AutoLoadingButton"
import ErrorAlert from "components/ErrorAlert"
import Loading from "components/Loading"
import PageHeader from "components/PageHeader"
import React, {FC, useContext, useEffect, useState} from "react"
import {useLocation, useNavigate, useParams} from "react-router-dom"
import {getCurrentStepLabel} from "utils/helpers/stepHelpers"
import {ApplicationPreview} from "./ApplicationPreview"
import {LoadingButton} from "@mui/lab"

export const ApplicationDetail: FC = () => {
  const {applicationId, userId} = useParams()
  const {session} = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  const [application, setApplication] = useState<Application | null>()
  const [user, setUser] = useState<User | null>()
  const [jobPosting, setJobPosting] = useState<JobPosting | null >()

  if (applicationId === undefined) {
    throw new Error("Application ID is undefined")
  }

  const refreshApplication = async () => {
    try {
      const newApplication = await session.application.detail(applicationId)
      setApplication(newApplication)
    } catch {
      setApplication(null)
    }
  }

  const deleteApplication = async () => {
    const shouldDelete = confirm(
      "Are you sure you want to permanently delete this application?"
    )
    if (!shouldDelete) {
      return
    }
    await session.application
      .delete(applicationId)
      .then(() => navigate("/applications"))
      .catch(() => alert("Error deleting application"))
  }

  useEffect(() => {
    refreshApplication()
  }, [applicationId])

  useEffect(() => {
    application &&
      session.user
        .detail(application.owner)
        .then(setUser)
        .catch(() => setUser(null))
        .then(() => {
          if (application.jobPosting) {
            session.jobPosting.detail(application.jobPosting)
              .then((response) => {
                setJobPosting(response)})
              .catch(() => setJobPosting(null))
          }
        })
  }, [application?.owner])

  if (application === null) {
    return <ErrorAlert>Error loading application</ErrorAlert>
  }

  if (user === null) {
    return <ErrorAlert>Error loading application user</ErrorAlert>
  }

  if (application === undefined || user === undefined) {
    return <Loading />
  }

  const pageTitle = `${application.applicant?.firstName??""} ${application.applicant?.lastName??""}`
  const jobPostingTitle = `${jobPosting?.title??""}`

  return (
    <Container maxWidth="md">
      <PageHeader
        title={pageTitle}
        subtitle={jobPostingTitle}
        breadcrumbs={
          userId
            ? [
                ["All Users", "/users"],
                [user.email, `/users/${user._id}`],
                [pageTitle, ""]
              ]
            : location.pathname.startsWith("/needs-review")
            ? [
                ["Needs Review", "/needs-review"],
                [pageTitle, ""]
              ]
            : [
                ["All Applications", "/applications"],
                [pageTitle, ""]
              ]
        }
      >
        <AutoLoadingButton onClick={deleteApplication} color="error">
          Delete
        </AutoLoadingButton>
      </PageHeader>

      <Stack spacing={2}>

      {application.signature && (
          <Card
            sx={(theme) => ({
              borderLeft: `5px solid ${theme.palette.primary.main}`
            })}
          >
            <CardContent>
              <Stack direction="row" spacing={2}>
                {application.review !== null   && (
                <Alert severity={application.review?.status===SignupFormStatus.Approved?"success":"error"}>
                  <AlertTitle>{application.review?.status===SignupFormStatus.Approved?"Approved":"Rejected"}</AlertTitle>
                  {application.review?.status===SignupFormStatus.Approved?"Application has beeen Approved":"Application Has been Rejected"}
                </Alert>
                )}
                
                { (application.review === null || application.review?.status===SignupFormStatus.Rejected) && (
              <LoadingButton 
                variant="outlined"
                color="success"
                onClick={() => {
                  session.application
                    .modify(
                      application._id,
                      makeObjectModification(application, {
                        review: {
                          status: SignupFormStatus.Approved,
                          at: new Date().toISOString(),
                          notes: "Approved"
                        },
                        readyForReview: false
                      })
                    )
                    .then(refreshApplication)
                    .catch(() => alert("Error updating application"))
                }}
              >
                Accept 
              </LoadingButton>
                )}
                {(application.review === null || application.review?.status===SignupFormStatus.Approved) && (
              <LoadingButton 
                variant="outlined"
                color="error"
                onClick={() => {
                  session.application
                    .modify(
                      application._id,
                      makeObjectModification(application, {
                        review: {
                          status: SignupFormStatus.Rejected,
                          at: new Date().toISOString(),
                          notes: "Rejected"
                        },
                        readyForReview: false
                      })
                    )
                    .then(refreshApplication)
                    .catch(() => alert("Error updating application"))
                }}
              >
                Reject 
              </LoadingButton>
                )}
              </Stack>
            </CardContent>
          </Card>
        )}

        <Card>
          <CardContent>
            <ApplicationPreview application={application} user={user} />
          </CardContent>
        </Card>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h3">View Raw Data</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p>Current Step: {getCurrentStepLabel(application)}</p>
            <p>User: {user.email}</p>
            <Box
              sx={{
                maxWidth: "100%",
                overflowY: "scroll",
                border: "1px solid grey",
                padding: 2
              }}
            >
              <pre>{JSON.stringify(application, null, 2)}</pre>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Container>
  )
}
